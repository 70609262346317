// react libraries
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Form, Input, InputNumber, Modal, Row, Select, message } from "antd";

// services
import { POST_API, POST_CATCH, PageDefaultProps, getToken } from "../../../services";

// components
import LoadItemComponent from "../../../components/LoadItemComponent";
import CardAdminComponent from "../../../components/CardAdminComponent";
import PageDefaultComponent from "../../../components/PageDefaultComponent";

// buttons components
import { TableReturnButton } from "../../../components/TableComponent/buttons";
import SelectSearchComponent from "../../../components/SelectSearchComponent";

const ServicosForm = ( { type, path, permission } : PageDefaultProps ) => {

    // route responsible
    const navigate = useNavigate()
    
    // params 
    const { ID } = useParams()

    // state
    const [ load, setLoad ] = useState(true)
    const [ has, setHas ] = useState('0')
    const [ loadButton, setLoadButton ] = useState(false)

    // form
    const [ form ] = Form.useForm()
    const [ certificate, setCertificate ] = useState<any>(null)

    // valid new or edit
    useEffect(() => {
        if (type === 'add') { setLoad(false) } else {
            setLoad(true)
            POST_API(`/${path}/search.php`, { token: getToken(), filter: JSON.stringify({ id: ID }), type }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    form.setFieldsValue(res.data)
                    setCertificate({id: res.data.certificate_id})
                    setHas(res.data.has_certificate)
                } else { Modal.warning({ title: 'Algo deu errado', content: res.msg }) }
            }).catch(POST_CATCH).finally( () => setLoad(false))
        }
    }, [type, path, form, ID]);

    // function save
    const onSend = (values: any) => {
        setLoadButton(true)
        values.ID = ID
        POST_API(`/${path}/save.php`, { token: getToken(), master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success(res.msg)
                navigate('..')
            } else { Modal.warning({ title: 'Algo deu errado', content: res.msg }) }
        }).catch(POST_CATCH).finally( () => setLoadButton(false) )
    }

    return (
        <PageDefaultComponent title="Gerenciamento de Serviços" items={[
            { title: type === 'add' ? 'Novo registro' : type === 'view' ? 'Visualizar registro' : 'Editar registro' },
        ]}>
            <Row gutter={[20,20]}>
                <Col md={24} xs={24}>
                    { load ? <LoadItemComponent /> :
                        <CardAdminComponent title="Serviços" subtitle={type === 'add' ? 'Novo registro' : type === 'view' ? 'Visualizar registro' : 'Editar registro'} options={
                            <Row justify={'end'} gutter={[8,8]}>
                                <TableReturnButton type={type} permission={permission} />
                            </Row>
                        }>
                            <Form disabled={type === 'view'} layout="vertical" onFinish={onSend} form={form}>
                                <Row gutter={[8,0]}>
                                    <Col xs={24} md={24}>
                                        <Form.Item name="name" label='Nome' rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                            <Input placeholder='Nome'/>
                                        </Form.Item>
                                    </Col>
                                    <Col md={4} xs={12}>
                                        <Form.Item name="price" label="Valor">
                                            <InputNumber style={{width: '100%'}} addonBefore="R$" placeholder='Valor' />
                                        </Form.Item>
                                    </Col>
                                    <Col md={4} xs={12}>
                                        <Form.Item name="expiration_days" label="Válido por">
                                            <InputNumber style={{width: '100%'}} addonAfter="Dias" placeholder='Válido por' />
                                        </Form.Item>
                                    </Col>
                                    <Col md={4} xs={12}>
                                        <Form.Item name="has_report" label="Tem relatório?">
                                            <Select>
                                                <Select.Option value="0">Não</Select.Option>
                                                <Select.Option value="1">Sim</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col md={4} xs={12}>
                                        <Form.Item name="need_technical_visit" label="Visita técnica?">
                                            <Select>
                                                <Select.Option value="0">Não</Select.Option>
                                                <Select.Option value="1">Sim</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col md={4} xs={12}>
                                        <Form.Item name="has_target_pest" label="Praga alvo?">
                                            <Select>
                                                <Select.Option value="0">Não</Select.Option>
                                                <Select.Option value="1">Sim</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col md={4} xs={12}>
                                        <Form.Item name="has_certificate" label="Tem certificado?">
                                            <Select onChange={setHas}>
                                                <Select.Option value="0">Não</Select.Option>
                                                <Select.Option value="1">Sim</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    { has === '0' ? null :
                                        <Col md={24} xs={24}>
                                            <Form.Item name="certificate_id" label="Certificado">
                                                <SelectSearchComponent disabled={type === 'view'} placeholder="Certificado" effect={certificate} value={form.getFieldValue('certificate_id')} url="/certificates/select.php" change={(v:any) => form.setFieldValue('certificate_id', v)} />
                                            </Form.Item>
                                        </Col>
                                    }
                                    <Col md={24} xs={24}>
                                        <Form.Item name="description" label="Descrição">
                                            <Input.TextArea placeholder="Descrição" rows={4} />
                                        </Form.Item>
                                    </Col>
                                    { type === 'view' ? null :
                                        <Col span={24}>
                                            <Button shape="round" htmlType="submit" className="btn-primary" type="primary" style={{float: 'right', marginLeft: 6}} loading={loadButton}>Salvar</Button>
                                            <Button shape="round" className="btn-default" type="default" style={{float: 'right'}} onClick={() => navigate('..')}>Cancelar</Button>
                                        </Col>
                                    }
                                </Row>
                            </Form>
                        </CardAdminComponent>
                    }
                </Col>
            </Row>
        </PageDefaultComponent>
    )

}

export default ServicosForm;
// react libraries
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Checkbox, Col, Form, Image, Input, InputNumber, List, Modal, Radio, Row, Switch, Typography } from "antd";

// services
import { OPR, POST_API, POST_CATCH, PageDefaultProps, getToken, verifyConfig } from "../../../services";

// components
import LoadItemComponent from "../../../components/LoadItemComponent";
import CardAdminComponent from "../../../components/CardAdminComponent";
import PageDefaultComponent from "../../../components/PageDefaultComponent";

// buttons components
import { TableReturnButton } from "../../../components/TableComponent/buttons";
import { CircleMarker, MapContainer, TileLayer } from "react-leaflet";
import { IoEyeOutline } from "react-icons/io5";

const OrdensDeServicoView = ( { type, path, permission } : PageDefaultProps ) => {
    
    // params 
    const { ID } = useParams()

    // state
    const [ load, setLoad ] = useState(true)
    const [ loadQuestion, setLoadQuestion ] = useState<boolean>(false)
    const [ question, setQuestion ] = useState<any[]>([])
    const [ photo, setPhoto ] = useState<any[]>([])
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState<any>(null);
    
    // form
    const [ formQuestion ] = Form.useForm()
    const [ data, setData ] = useState<any>(null)

    // valid new or edit
    useEffect(() => {
        onLoadPhotos()
        onLoadQuestion()
        if (type === 'add') { 
            setLoad(false)
        } else {
            setLoad(true)
            POST_API(`/${path}/search.php`, { token: getToken(), filter: JSON.stringify({ id: ID }), type }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    setData(res.data)
                    formQuestion.setFieldsValue(res.data.info)
                } else { Modal.warning({ title: 'Algo deu errado', content: res.msg }) }
            }).catch(POST_CATCH).finally( () => {setLoad(false)})
        }
    }, [type, path, formQuestion, ID]);

    const onLoadPhotos = () => {
        POST_API(`/budget_photo/search.php`, { token: getToken(), filter: JSON.stringify({ budget_id: ID }), type: 'list' }).then(rs => rs.json()).then(res => {
            if (res.return) {
                setPhoto(res.data)
            } else {
                Modal.warning({ title: 'Algo deu errado', content: res.msg })
            }
        }).catch(POST_CATCH)
    }

    const onLoadQuestion = () => {
        setLoadQuestion(true)
        POST_API(`/question/search.php`, { token: getToken(), type: 'list' }).then(rs => rs.json()).then(res => {
            if (res.return) {
                setQuestion(res.data)
            } else {
                Modal.warning({ title: 'Algo deu errado', content: res.msg })
            }
        }).catch(POST_CATCH).finally( () => setLoadQuestion(false))
    }

    const photoPreview = ( item:any ) => {      
        setPreviewImage(item);
        setPreviewOpen(true);
    }

    return (
        <PageDefaultComponent title="Gerenciamento de Ordens de Serviço" items={[
            { title: type === 'add' ? 'Novo registro' : type === 'view' ? 'Visualizar registro' : 'Prestadores' },
        ]}>
            <Row gutter={[20,20]}>
                <Col md={24} xs={24}>
                    { load ? <LoadItemComponent /> :
                        <Row gutter={[16,16]}>
                            <Col md={24} xs={24}>
                                <CardAdminComponent title="Ordem de Serviço" subtitle={'Detalhes'} options={
                                <Row justify={'end'} gutter={[8,8]}>
                                    <TableReturnButton type={type} permission={permission} />
                                </Row>
                                }>
                                    <Row gutter={[8,8]}>
                                        <Col xs={24} md={2}>
                                            <Typography className="ord-title">N° O.S.:</Typography>
                                            <Typography className="ord-value">#{data.id}</Typography>
                                        </Col>
                                        <Col xs={24} md={17}>
                                            <Typography className="ord-title">Nome cliente:</Typography>
                                            <Typography className="ord-value">{data.client_name}</Typography>
                                        </Col>
                                        <Col xs={24} md={5}>
                                            <Typography className="ord-title">Data Prevista para Execução:</Typography>
                                            <Typography className="ord-value">{data.scheduled_execution_date ? data.scheduled_execution_date_format : '-'}</Typography>
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <Typography className="ord-title">Endereço:</Typography>
                                            <Typography className="ord-value">{data.client_address}</Typography>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Typography className="ord-title">E-mail:</Typography>
                                            <Typography className="ord-value">{ data.client_email ? data.client_email : '-' }</Typography>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Typography className="ord-title">Celular:</Typography>
                                            <Typography className="ord-value">{ data.client_cellphone ? data.client_cellphone : '-' }</Typography>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Typography className="ord-title">Telefone:</Typography>
                                            <Typography className="ord-value">{ data.client_telephone ? data.client_telephone : '-' }</Typography>
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <Typography className="ord-title">Observações:</Typography>
                                            <Typography className="ord-value">{ data.description ? data.description : '-' }</Typography>
                                        </Col>
                                    </Row>
                                </CardAdminComponent>
                            </Col>
                            <Col md={24} xs={24}>
                                <CardAdminComponent title="Ordem de Serviço" subtitle={'Serviços'}>
                                    <Row gutter={[8,8]}>
                                        { data.services.map((v:any, i:number) => (
                                            <Col xs={24} md={24} key={i}>
                                                <Row gutter={[8,8]} style={{borderLeft: '2px solid var(--color01)', borderRadius: 8, paddingLeft: 6}}>
                                                    <Col xs={24} md={24}>
                                                        <Typography className="ord-title">Serviço #{i+1}:</Typography>
                                                        <Typography className="ord-value">{ v.name ? v.name : '-' }</Typography>
                                                    </Col>
                                                    { !verifyConfig([OPR]) ? (
                                                        <Col xs={24} md={12}>
                                                            <Typography className="ord-title">Valor:</Typography>
                                                            <Typography className="ord-value">{ v.price_name ? v.price_name : '-' }</Typography>
                                                        </Col>
                                                    ) : null }
                                                    <Col xs={24} md={4}>
                                                        <Typography className="ord-title">Visita técnica:</Typography>
                                                        <Typography className="ord-value">{ v.has_technical_visit_name ? v.has_technical_visit_name : '-' }</Typography>
                                                    </Col>
                                                    <Col xs={24} md={4}>
                                                        <Typography className="ord-title">Certificado:</Typography>
                                                        <Typography className="ord-value">{ v.need_certificate_name ? v.need_certificate_name : '-' }</Typography>
                                                    </Col>
                                                    <Col xs={24} md={4}>
                                                        <Typography className="ord-title">Relatório:</Typography>
                                                        <Typography className="ord-value">{ v.need_report_name ? v.need_report_name : '-' }</Typography>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        )) }
                                    </Row>
                                </CardAdminComponent>
                            </Col>
                            <Col md={24} xs={24}>
                                <CardAdminComponent title="Ordem de Serviço" subtitle={'Financeiro'}>
                                    <Row gutter={[8,8]}>
                                        <Col xs={24} md={15}>
                                            <Typography className="ord-title">Forma de pagamento:</Typography>
                                            <Typography className="ord-value">{ data.payment_method_id ? data.payment_method_name : '-' }</Typography>
                                        </Col>
                                        { !verifyConfig([OPR]) ? (
                                            <>
                                                <Col xs={24} md={3}>
                                                    <Typography className="ord-title">Valor:</Typography>
                                                    <Typography className="ord-value">{ data.price ? data.price_name : '-' }</Typography>
                                                </Col>
                                                <Col xs={24} md={3}>
                                                    <Typography className="ord-title">Desconto:</Typography>
                                                    <Typography className="ord-value">{ data.discount ? data.discount_name : '-' }</Typography>
                                                </Col>
                                                <Col xs={24} md={3}>
                                                    <Typography className="ord-title">Total a pagar:</Typography>
                                                    <Typography className="ord-value">{ data.total ? data.total_name : '-' }</Typography>
                                                </Col>
                                            
                                            </>
                                        ) : null }
                                    </Row>
                                </CardAdminComponent>
                            </Col>
                            <Col md={24} xs={24}>
                                <CardAdminComponent title="Ordem de Serviço" subtitle={'Informações adicionais'}>
                                    <Form layout="vertical" form={formQuestion} disabled={true}>
                                        <List
                                            dataSource={question}
                                            loading={loadQuestion}
                                            locale={{emptyText: 'Nenhuma informação adicional encontrada'}}
                                            renderItem={(item:any, index) => (
                                                <List.Item key={item.id}>
                                                    <Form.Item name={`question#${item.id}`} label={item.question}  style={{width: '100%', margin: 0}}>
                                                    { item.type_question === 'open' ? <Input.TextArea rows={4} readOnly style={{width: '100%'}} /> : 
                                                        item.type_question === 'close' ? <Radio.Group> { item.answers.map((v:any, i:any) => <Radio value={v} key={i}>{v}</Radio> ) } </Radio.Group> :
                                                        item.type_question === 'multiple' ? <Checkbox.Group> { item.answers.map((v:any, i:any) => <Checkbox value={v} key={i}>{v}</Checkbox> ) } </Checkbox.Group> : null }
                                                    </Form.Item>
                                                </List.Item>
                                            )}
                                        />
                                    </Form>
                                </CardAdminComponent>
                            </Col>
                            { data.tecnica.length > 0 ? (
                                <Col md={24} xs={24}>
                                    <CardAdminComponent title="Ordem de Serviço" subtitle={'Prestadores para visita técnica'}>
                                            <List
                                                size="small"
                                                locale={{emptyText: 'Nenhum prestador encontrado'}}
                                                dataSource={data.tecnica}
                                                renderItem={(item:any, index) => (
                                                    <List.Item key={item.id}>
                                                        <List.Item.Meta title={item.name} description={item.user_type_name} />
                                                        <Switch disabled={true} defaultChecked={Boolean(Number(item.was_present))} unCheckedChildren="Não presente" checkedChildren="Presente" style={{marginRight: 10}} />
                                                    </List.Item>
                                                )}
                                            />
                                    </CardAdminComponent>
                                </Col>
                            ) : null }
                            { data.services.map((v:any, i:number) => v.has_technical_visit === '1' ? (
                                <Col xs={24} md={24}>
                                    <CardAdminComponent title={v.name} subtitle={'Visita técnica'}>
                                        <Form layout="vertical" initialValues={v.info} disabled={true}>
                                            <List
                                                dataSource={v.question}
                                                locale={{emptyText: 'Nenhuma informação adicional encontrada'}}
                                                renderItem={(item:any, index) => (
                                                    <List.Item key={item.id}>
                                                        <Form.Item name={`question#${item.id}`} label={item.question}  style={{width: '100%', margin: 0}}>
                                                        { item.type_question === 'open' ? <Input.TextArea rows={4} style={{width: '100%'}} /> : 
                                                            item.type_question === 'close' ? <Radio.Group> { item.answers.map((v:any, i:any) => <Radio value={v} key={i}>{v}</Radio> ) } </Radio.Group> :
                                                            item.type_question === 'multiple' ? <Checkbox.Group> { item.answers.map((v:any, i:any) => <Checkbox value={v} key={i}>{v}</Checkbox> ) } </Checkbox.Group> : null }
                                                        </Form.Item>
                                                    </List.Item>
                                                )}
                                            />
                                        </Form>
                                    </CardAdminComponent>
                                </Col>
                            ) : null ) }
                            <Col md={24} xs={24}>
                                <CardAdminComponent title="Ordem de Serviço" subtitle={'Prestadores para execução'}>
                                    <List
                                        size="small"
                                        locale={{emptyText: 'Nenhum prestador encontrado'}}
                                        dataSource={data.prestadores}
                                        renderItem={(item:any, index) => (
                                            <List.Item key={item.id}>
                                                <List.Item.Meta title={item.name} description={item.user_type_name} />
                                                <Switch disabled={true} defaultChecked={Boolean(Number(item.was_present))} unCheckedChildren="Não presente" checkedChildren="Presente" style={{marginRight: 10}} />
                                            </List.Item>
                                        )}
                                    />
                                </CardAdminComponent>
                            </Col>
                            <Col xs={24} md={24}>
                                <CardAdminComponent title="Ordem de Serviço" subtitle={'Acompanhante responsável'}>
                                    <Input value={data.companion} readOnly placeholder="Nome do acompanhante responsável" />
                                </CardAdminComponent>
                            </Col>
                            <Col xs={24} md={24}>
                                <CardAdminComponent title="Ordem de Serviço" subtitle={'Filtros trocados (se necessário)'}>
                                    <Input value={data.qty} readOnly placeholder="Quantidade filtros trocados" />
                                </CardAdminComponent>
                            </Col>
                            <Col xs={24} md={24}>
                                <CardAdminComponent title="Ordem de Serviço" subtitle={'Produtos utilizados'}>
                                    <List
                                        size="small"
                                        locale={{emptyText: 'Nenhum produto selecionado'}}
                                        dataSource={data.produtos}
                                        renderItem={(item:any, index) => (
                                            <List.Item key={item.id}>
                                                <Row gutter={[8,8]} style={{width: '100%'}}>
                                                    <Col xs={24} md={14}>
                                                        <Row>
                                                            <List.Item.Meta title={`Produto #${index+1}`} description={<Input value={item.name} readOnly style={{width: '100%'}} />} />
                                                        </Row>
                                                    </Col>
                                                    <Col xs={12} md={5}>
                                                        <List.Item.Meta title={'Dosagem'} description={<InputNumber readOnly max={item.stock} min={0} defaultValue={item.dosage} style={{width: '100%'}} addonAfter={item.unit_measurement_acronym} />} />
                                                    </Col>
                                                    <Col xs={12} md={5}>
                                                        <List.Item.Meta title={'Quantidade aplicada'} description={<InputNumber readOnly min={0} defaultValue={item.applied} style={{width: '100%'}} addonAfter={item.unit_measurement_qtd_acronym} />} />
                                                    </Col>
                                                </Row>
                                            </List.Item>
                                        )}
                                    />
                                </CardAdminComponent>
                            </Col>
                            <Col xs={24} md={24}>
                                <CardAdminComponent title="Ordem de Serviço" subtitle={'Fotos da operação'}>
                                    <Row gutter={[8,8]}>
                                        { photo.map((v:any, i:any) => (
                                            <Col>
                                                <div className="gallery-card">
                                                    <Image preview={false} src={`${v.url}`} />
                                                    <div className="gallery-filter"> </div>
                                                    <Row className="gallery-icons" gutter={[8,8]}>
                                                        <Col><IoEyeOutline onClick={() => photoPreview(v)} className="gallery-icon" /></Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        )) }
                                        { previewImage && (
                                            <Image
                                                wrapperStyle={{ display: 'none' }}
                                                preview={{
                                                    destroyOnClose: true,
                                                    visible: previewOpen,
                                                    onVisibleChange: (visible) => setPreviewOpen(visible),
                                                    afterOpenChange: (visible) => !visible && setPreviewImage(''),
                                                    toolbarRender: () => null,
                                                    imageRender: () => {

                                                        return (
                                                            <div>
                                                                <Image style={{maxHeight: '100vh'}} src={`${previewImage.url}`} preview={false} />
                                                                <div className="image-coord">
                                                                    <Row justify={'space-between'} style={{padding: 10, flexWrap: 'nowrap'}}>
                                                                        <Col>
                                                                            <Typography className="image-coord-date">{previewImage.created_at_format}</Typography>
                                                                            <Typography className="image-coord-resp">{previewImage.created_name}</Typography>
                                                                            <Typography className="image-coord-city">{previewImage.city}</Typography>
                                                                            <Typography className="image-coord-coord">{previewImage.coord}</Typography>
                                                                        </Col>
                                                                        <Col>
                                                                            <MapContainer center={[Number(previewImage.lat), Number(previewImage.log)]} zoom={13} scrollWheelZoom={true} style={{width:'120px',height:'100px'}} >
                                                                                <TileLayer
                                                                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' 
                                                                                    url="http://tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                                                />
                                                                                <CircleMarker center={[Number(previewImage.lat), Number(previewImage.log)]} pathOptions={{ color: 'green'}} radius={10}></CircleMarker>
                                                                            </MapContainer>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </div>
                                                        )
                                                            
                                                    }
                                                } }
                                                src={previewImage}
                                            />
                                        )}
                                    </Row>
                                </CardAdminComponent>
                            </Col>
                            { data.services.map((v:any, i:number) => v.need_report === '1' ? (
                                <Col xs={24} md={24}>
                                    <CardAdminComponent title={v.name} subtitle={'Relatório'}>
                                        <Form layout="vertical" initialValues={v.info_report} disabled={true}>
                                            <List
                                                dataSource={v.report}
                                                locale={{emptyText: 'Nenhuma informação adicional encontrada'}}
                                                renderItem={(item:any, index) => (
                                                    <List.Item key={item.id}>
                                                        <Form.Item name={`question#${item.id}`} label={item.question}  style={{width: '100%', margin: 0}}>
                                                        { item.type_question === 'open' ? <Input.TextArea rows={4} style={{width: '100%'}} /> : 
                                                            item.type_question === 'close' ? <Radio.Group> { item.answers.map((v:any, i:any) => <Radio value={v} key={i}>{v}</Radio> ) } </Radio.Group> :
                                                            item.type_question === 'multiple' ? <Checkbox.Group> { item.answers.map((v:any, i:any) => <Checkbox value={v} key={i}>{v}</Checkbox> ) } </Checkbox.Group> : null }
                                                        </Form.Item>
                                                    </List.Item>
                                                )}
                                            />
                                        </Form>
                                    </CardAdminComponent>
                                </Col>
                            ) : null ) }
                        </Row>
                    }
                </Col>
            </Row>
        </PageDefaultComponent>
    )

}

export default OrdensDeServicoView;
// react libraries
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Form, Input, Modal, Row, Select, message } from "antd";

// services
import { ADM, ANTINPUT, GRN, OPR, POST_API, POST_CATCH, PageDefaultProps, REP, getToken } from "../../../services";

// components
import LoadItemComponent from "../../../components/LoadItemComponent";
import CardAdminComponent from "../../../components/CardAdminComponent";
import PageDefaultComponent from "../../../components/PageDefaultComponent";

// buttons components
import { TableReturnButton } from "../../../components/TableComponent/buttons";
import ReactInputMask from "react-input-mask";

const FornecedoresForm = ( { type, path, permission } : PageDefaultProps ) => {

    // route responsible
    const navigate = useNavigate()
    
    // params 
    const { ID } = useParams()

    // state
    const [ load, setLoad ] = useState(true)
    const [ doc, setDoc ] = useState(false)
    const [ loadButton, setLoadButton ] = useState(false)
    const [ loadCEP, setLoadCEP ] = useState(false)

    // form
    const [form] = Form.useForm()

    // valid new or edit
    useEffect(() => {
        if (type === 'add') { setLoad(false) } else {
            setLoad(true)
            POST_API(`/${path}/search.php`, { token: getToken(), filter: JSON.stringify({ id: ID }), type }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    form.setFieldsValue(res.data)
                    setDoc(res.data.cpf_cnpj.length > 14)
                } else { Modal.warning({ title: 'Algo deu errado', content: res.msg }) }
            }).catch(POST_CATCH).finally( () => setLoad(false))
        }
    }, [type, path, form, ID]);

    // function save
    const onSend = (values: any) => {
        setLoadButton(true)
        values.ID = ID
        POST_API(`/${path}/save.php`, { token: getToken(), master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success(res.msg)
                navigate('..')
            } else { Modal.warning({ title: 'Algo deu errado', content: res.msg }) }
        }).catch(POST_CATCH).finally( () => setLoadButton(false) )
    }

    const onValidDoc = ( event: any ): any => {
        var text = String(event.target.value).replaceAll('_', '')
        if (event.which !== 9) {
            setDoc(text.length > 13)
        }
    }

    // search cep
    const onCEP = async () => {
        setLoadCEP(true)
        await fetch('https://viacep.com.br/ws/' + String(form.getFieldValue('cep')).replaceAll('-', '') + '/json/').then(rs => rs.json()).then(res => {
            if (res?.erro) {
                message.warning('CEP inválido')
            } else {
                form.setFieldValue('street', res.logradouro)
                form.setFieldValue('district', res.bairro)
                form.setFieldValue('city', res.localidade)
                form.setFieldValue('uf', res.uf)
            }
        }).catch(POST_CATCH).finally( () => setLoadCEP(false) )
    }

    return (
        <PageDefaultComponent title="Gerenciamento de Fornecedores" items={[
            { title: type === 'add' ? 'Novo registro' : type === 'view' ? 'Visualizar registro' : 'Editar registro' },
        ]}>
            <Row gutter={[20,20]}>
                <Col md={24} xs={24}>
                    { load ? <LoadItemComponent /> :
                        <CardAdminComponent title="Fornecedores" subtitle={type === 'add' ? 'Novo registro' : type === 'view' ? 'Visualizar registro' : 'Editar registro'} options={
                            <Row justify={'end'} gutter={[8,8]}>
                                <TableReturnButton type={type} permission={permission} />
                            </Row>
                        }>
                            <Form disabled={type === 'view'} layout="vertical" onFinish={onSend} form={form}>
                                <Row gutter={[8,0]}>
                                    <Col xs={24} md={4}>
                                        <Form.Item name="cpf_cnpj" label={ doc ? 'CNPJ' : 'CPF' }>
                                            <ReactInputMask disabled={type === 'view'} onKeyDown={onValidDoc} mask={ doc ? '99.999.999/9999-99' : '999.999.999-99' } placeholder={ doc ? 'CNPJ' : 'CPF' } className={ANTINPUT} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={16}>
                                        <Form.Item name="name" label={ doc ? 'Razão social' : 'Nome completo' } rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                            <Input placeholder={ doc ? 'Razão social' : 'Nome completo' } />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={4}>
                                        <Form.Item name="rg_ie" label={ doc ? 'IE' : 'RG' }>
                                            <Input placeholder={ doc ? 'IE' : 'RG' } />
                                        </Form.Item>
                                    </Col>
                                    <Col md={12} xs={24}>
                                        <Form.Item name="email" label="E-mail">
                                            <Input placeholder='E-mail' type="email" />
                                        </Form.Item>
                                    </Col>
                                    <Col md={6} xs={12}>
                                        <Form.Item name="telephone" label="Telefone">
                                            <ReactInputMask disabled={type === 'view'} mask='(99) 9999-9999' placeholder='Telefone' className={ANTINPUT} />
                                        </Form.Item>
                                    </Col>
                                    <Col md={6} xs={12}>
                                        <Form.Item name="cellphone" label="Celular">
                                            <ReactInputMask disabled={type === 'view'} mask='(99) 99999-9999' placeholder='Telefone' className={ANTINPUT} />
                                        </Form.Item>
                                    </Col>
                                    <Col md={3} xs={24}>
                                        <Form.Item name="cep" label="CEP">
                                            <ReactInputMask disabled={type === 'view'} mask='99999-999' placeholder='CEP' className={ANTINPUT} onBlur={onCEP} />
                                        </Form.Item>
                                    </Col>
                                    <Col md={18} xs={24}>
                                        <Form.Item name="street" label="Logradouro">
                                            <Input placeholder="Logradouro" />
                                        </Form.Item>
                                    </Col>
                                    <Col md={3} xs={24}>
                                        <Form.Item name="numb" label="Número">
                                            <Input placeholder="Número" />
                                        </Form.Item>
                                    </Col>
                                    <Col md={12} xs={24}>
                                        <Form.Item name="district" label="Bairro">
                                            <Input placeholder="Bairro" />
                                        </Form.Item>
                                    </Col>
                                    <Col md={10} xs={24}>
                                        <Form.Item name="city" label="Cidade">
                                            <Input placeholder="Cidade" />
                                        </Form.Item>
                                    </Col>
                                    <Col md={2} xs={24}>
                                        <Form.Item name="uf" label="UF" >
                                            <Input placeholder="UF" maxLength={2} />
                                        </Form.Item>
                                    </Col>
                                    { type === 'view' ? null :
                                        <Col span={24}>
                                            <Button shape="round" htmlType="submit" className="btn-primary" type="primary" style={{float: 'right', marginLeft: 6}} loading={loadButton}>Salvar</Button>
                                            <Button shape="round" className="btn-default" type="default" style={{float: 'right'}} onClick={() => navigate('..')}>Cancelar</Button>
                                        </Col>
                                    }
                                </Row>
                            </Form>
                        </CardAdminComponent>
                    }
                </Col>
            </Row>
        </PageDefaultComponent>
    )

}

export default FornecedoresForm;
// css
import { Typography } from 'antd';
import './index.css';

// interface
interface MVVInterface {
    icon: any,
    title: string,
    text: string
}

const MVVComponent = ( { icon, title, text } : MVVInterface ) => {

    return (
        <div className='mvv'>
            <center>{icon}</center>
            <Typography className='mvv-title'>{title}</Typography>
            <Typography className='mvv-text'>{text}</Typography>
        </div>
    )

}

export default MVVComponent;
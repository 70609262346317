// react libraries
import { Button, Col, Form, Input, Layout, Modal, Row, Typography, message } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';

// icons
import { IoAt, IoLockClosedOutline } from 'react-icons/io5';

// css
import './index.css';
import { ANTINPUT, POST_API, POST_CATCH, setConfig, setToken } from '../../services';
import ReactInputMask from 'react-input-mask';

const Cadastrese = () => {

    // responsible route
    const navigate = useNavigate();

    // states
    const [ type, setType ] = useState<'' | 'cliente' | 'colaborador'>('');
    const [ load, setLoad ] = useState(false)
    const [ loadCEP, setLoadCEP ] = useState(false)
    const [ doc, setDoc ] = useState(false)

    // logo
    const logo = '../assets/img/logo.png';

    // login
    const onSend = (values: any) => {
        setLoad(true)
        POST_API(`/client/save.php`, { token: 'X', master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                Modal.success({ title: 'Sucesso', content: 'Você realizou o cadastro com sucesso' })
                navigate('/login')
            } else { Modal.warning({ title: 'Algo deu errado', content: res.msg }) }
        }).catch(POST_CATCH).finally( () => setLoad(false) )
    }

    const [ form ] = Form.useForm()

    const onValidDoc = ( event: any ): any => {
        var text = String(event.target.value).replaceAll('_', '')
        if (event.which !== 9) {
            setDoc(text.length > 13)
        }
    }

    // search cep
    const onCEP = async () => {
        setLoadCEP(true)
        await fetch('https://viacep.com.br/ws/' + String(form.getFieldValue('cep')).replaceAll('-', '') + '/json/').then(rs => rs.json()).then(res => {
            if (res?.erro) {
                message.warning('CEP inválido')
            } else {
                form.setFieldValue('street', res.logradouro)
                form.setFieldValue('district', res.bairro)
                form.setFieldValue('city', res.localidade)
                form.setFieldValue('uf', res.uf)
            }
        }).catch(POST_CATCH).finally( () => setLoadCEP(false) )
    }

    return (
        <Layout style={{overflowX: 'hidden'}}>
            <Row className='st-row' justify={'center'} align={'middle'}>
                <Col> <img alt="NLG Solutions" src={logo} className='st-img' /> </Col>
                <Col>
                    <div className='st-cadastrese'>
                        <Typography className='st-title'>Ficha de cadastro para novos clientes</Typography>
                        <Form form={form} layout='vertical' onFinish={onSend}>
                            <Row gutter={[8,0]}>
                                <Col xs={24} md={4}>
                                    <Form.Item name="cpf_cnpj" label={'CPF/CNPJ'}>
                                        <ReactInputMask maskChar="" onKeyDown={onValidDoc} mask={ doc ? '99.999.999/9999-99' : '999.999.999-99' } placeholder='CPF/CNPJ' className={ANTINPUT} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={16}>
                                    <Form.Item name="name" label={ doc ? 'Razão social' : 'Nome completo' } rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                        <Input placeholder={ doc ? 'Razão social' : 'Nome completo' } />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={4}>
                                    <Form.Item name="rg_ie" label={ doc ? 'IE' : 'RG' }>
                                        <Input placeholder={ doc ? 'IE' : 'RG' } />
                                    </Form.Item>
                                </Col>
                                <Col md={4} xs={24}>
                                    <Form.Item name="birthday" label={ doc ? 'Data início atividades' : 'Data de nascimento' }>
                                        <Input placeholder={ doc ? 'Data início atividades' : 'Data de nascimento' } type="date" />
                                    </Form.Item>
                                </Col>
                                <Col md={10} xs={24}>
                                    <Form.Item name="email" label="E-mail" rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                        <Input placeholder='E-mail' type="email" />
                                    </Form.Item>
                                </Col>
                                <Col md={5} xs={12}>
                                    <Form.Item name="telephone" label="Telefone">
                                        <ReactInputMask mask='(99) 9999-9999' placeholder='Telefone' className={ANTINPUT} />
                                    </Form.Item>
                                </Col>
                                <Col md={5} xs={12}>
                                    <Form.Item name="cellphone" label="Celular">
                                        <ReactInputMask mask='(99) 99999-9999' placeholder='Telefone' className={ANTINPUT} />
                                    </Form.Item>
                                </Col>
                                <Col md={3} xs={24}>
                                    <Form.Item name="cep" label="CEP">
                                        <ReactInputMask mask='99999-999' placeholder='CEP' className={ANTINPUT} onBlur={onCEP} />
                                    </Form.Item>
                                </Col>
                                <Col md={18} xs={24}>
                                    <Form.Item name="street" label="Logradouro">
                                        <Input placeholder="Logradouro" />
                                    </Form.Item>
                                </Col>
                                <Col md={3} xs={24}>
                                    <Form.Item name="numb" label="Número">
                                        <Input placeholder="Número" />
                                    </Form.Item>
                                </Col>
                                <Col md={12} xs={24}>
                                    <Form.Item name="district" label="Bairro">
                                        <Input placeholder="Bairro" />
                                    </Form.Item>
                                </Col>
                                <Col md={10} xs={24}>
                                    <Form.Item name="city" label="Cidade">
                                        <Input placeholder="Cidade" />
                                    </Form.Item>
                                </Col>
                                <Col md={2} xs={24}>
                                    <Form.Item name="uf" label="UF" >
                                        <Input placeholder="UF" maxLength={2} />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Button shape="round" htmlType="submit" className="btn-primary" type="primary" style={{float: 'right', marginLeft: 6}} loading={load}>Salvar</Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Col>
                <Col> <Typography className='st-text'>Desenvolvido por <Link target="_blank" to="https://adsolucoestecnologia.com.br">AD Soluções em Tecnologia</Link></Typography> </Col>
            </Row>
        </Layout>
    )

}

export default Cadastrese;
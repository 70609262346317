// react libraries
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Drawer, Form, Input, List, Modal, Row, Spin, Switch, Typography, message } from "antd";

// services
import { POST_API, POST_CATCH, PageDefaultProps, getToken } from "../../../services";

// components
import LoadItemComponent from "../../../components/LoadItemComponent";
import CardAdminComponent from "../../../components/CardAdminComponent";
import PageDefaultComponent from "../../../components/PageDefaultComponent";

// buttons components
import { TableReturnButton } from "../../../components/TableComponent/buttons";

// icons
import { FaCheck } from 'react-icons/fa';
import { IoClose } from "react-icons/io5";

const OrdensDeServicoForm = ( { type, path, permission } : PageDefaultProps ) => {

    // route responsible
    const navigate = useNavigate()
    
    // params 
    const { ID } = useParams()

    // state
    const [ load, setLoad ] = useState(true)
    const [ loadPrestadores, setLoadPrestadores ] = useState(true)
    const [ loadPrestadoresSelect, setLoadPrestadoresSelect ] = useState(true)
    const [ loadButton, setLoadButton ] = useState(false)
    const [ open, setOpen ] = useState<boolean>(false)
    const [ search, setSearch ] = useState<string>('')
    const [ prestadores, setPrestadores ] = useState<any[]>([])
    const [ prestadoresSelected, setPrestadoresSelected ] = useState<any[]>([])
    
    // form
    const [ formPrestadores ] = Form.useForm()

    // valid new or edit
    useEffect(() => {
        if (type === 'add') { 
            setLoad(false)
        } else {
            setLoad(true)
            POST_API(`/${path}/search.php`, { token: getToken(), filter: JSON.stringify({ id: ID }), type }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    formPrestadores.setFieldsValue(res.data.prestadores)
                    setPrestadoresSelected(res.data.prestadores)
                } else { Modal.warning({ title: 'Algo deu errado', content: res.msg }) }
            }).catch(POST_CATCH).finally( () => {setLoad(false);setLoadPrestadoresSelect(false)})
        }
    }, [type, path, formPrestadores, ID]);

    // function save
    const onSend = (values: any) => {
        setLoadButton(true)
        values.ID = ID
        POST_API(`/${path}/save.php`, { token: getToken(), master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success(res.msg)
                navigate('..')
            } else { Modal.warning({ title: 'Algo deu errado', content: res.msg }) }
        }).catch(POST_CATCH).finally( () => setLoadButton(false) )
    }

    const onLoadPrestadores = () => {
        setLoadPrestadores(true)
        POST_API(`/user/search.php`, { token: getToken(), pagination: JSON.stringify({current: 1, page: 10}), sorter: JSON.stringify({selectColumn: 'user.name', order: 'ASC'}), search, type: 'list' }).then(rs => rs.json()).then(res => {
            if (res.return) {
                setPrestadores(res.data)
            } else {
                Modal.warning({ title: 'Algo deu errado', content: res.msg })
            }
        }).catch(POST_CATCH).finally( () => setLoadPrestadores(false))
    }

    const cancelPrestador = (index: any) => {
        setLoadPrestadoresSelect(true)
        var temp = prestadoresSelected;
        temp.splice(index, 1)
        setPrestadoresSelected(temp)
        setTimeout(() => {
            setLoadPrestadoresSelect(false)
        }, 1);
    }

    useEffect(() => {
        onLoadPrestadores()
    }, [search])

    useEffect(() => {
        setOpen(false)
        formPrestadores.setFieldValue('prestadores', prestadoresSelected)
    }, [prestadoresSelected])

    return (
        <PageDefaultComponent title="Gerenciamento de Ordens de Serviço" items={[
            { title: type === 'add' ? 'Novo registro' : type === 'view' ? 'Visualizar registro' : 'Prestadores' },
        ]}>
            <Row gutter={[20,20]}>
                <Col md={24} xs={24}>
                    { load ? <LoadItemComponent /> :
                        <CardAdminComponent title="Ordens de Serviço" subtitle={type === 'add' ? 'Novo registro' : type === 'view' ? 'Visualizar registro' : 'Prestadores'} options={
                            <Row justify={'end'} gutter={[8,8]}>
                                <TableReturnButton type={type} permission={permission} />
                            </Row>
                        }>
                            <Form layout="vertical" disabled={type === 'view'} onFinish={onSend} form={formPrestadores}>
                                <Row gutter={[8,0]}>
                                    <Col xs={24} md={24}>
                                        <Form.Item name="prestadores" label="Prestadores" rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                            <List
                                                header={
                                                    <Row justify={'space-between'} align={'middle'}>
                                                        <Col><Typography style={{fontWeight: 600}}>Prestadores selecionados</Typography></Col>
                                                        <Col><Button onClick={() => setOpen(true)} type="primary" className="btn-primary" shape="round" size="small">Selecionar novo</Button></Col>
                                                    </Row>
                                                }
                                                size="small"
                                                bordered
                                                locale={{emptyText: 'Nenhum prestador selecionado'}}
                                                dataSource={prestadoresSelected}
                                                loading={loadPrestadoresSelect}
                                                renderItem={(item, index) => (
                                                    <List.Item key={item.id}>
                                                        <List.Item.Meta title={item.name} description={item.user_type_name} />
                                                        <Switch disabled={true} defaultChecked={Boolean(Number(item.was_present))} unCheckedChildren="Não presente" checkedChildren="Presente" style={{marginRight: 10}} />
                                                        { type === 'view' ? null : <IoClose size={20} className="list-check" onClick={() => cancelPrestador(index)} /> }
                                                    </List.Item>
                                                )}
                                            />
                                        </Form.Item>
                                    </Col>
                                    { type === 'view' ? null :
                                        <Col span={24}>
                                            <Button shape="round" htmlType="submit" className="btn-primary" type="primary" style={{float: 'right', marginLeft: 6}} loading={loadButton}>Salvar</Button>
                                            <Button shape="round" className="btn-default" type="default" style={{float: 'right'}} onClick={() => navigate('..')}>Cancelar</Button>
                                        </Col>
                                    }
                                </Row>
                            </Form>
                        </CardAdminComponent>
                    }
                </Col>
            </Row>
            <Drawer open={open} onClose={() => setOpen(false)} title="Selecionar prestador" destroyOnClose>
                <List
                    header={ <Row justify={'space-between'} align={'middle'}> <Col span={24}><Input onChange={(v) => setSearch(v.target.value)} placeholder="Pesquise aqui"/></Col> </Row> }
                    size="small"
                    locale={{emptyText: loadPrestadores ? <Spin/> : 'Nenhum serviço encontrado'}}
                    dataSource={prestadores}
                    renderItem={(item) => (
                        <List.Item key={item.id}>
                            <List.Item.Meta title={item.name} description={item.user_type_name} />
                            <FaCheck className="list-check" onClick={() => setPrestadoresSelected([...prestadoresSelected, item])} />
                        </List.Item>
                    )}
                />
            </Drawer>
        </PageDefaultComponent>
    )

}

export default OrdensDeServicoForm;
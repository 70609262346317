// react libraries
import { Col, Row, Typography } from 'antd';

// css
import './index.css';

// interface
interface CardAdminInterface {
    title?: string,
    subtitle?: string,
    options?: any,
    children: any
}

const CardAdminComponent = ( { title, subtitle, options, children } : CardAdminInterface ) => {

    return (
        <div className='crd-adm'>
            <Row gutter={[8,8]} justify={'space-between'}>
                { title ? (
                    <Col className='crd-adm-head-col'>
                        <Typography className='crd-adm-head-title'>{title}</Typography>
                        <Typography className='crd-adm-head-subtitle'>| {subtitle}</Typography>
                    </Col>
                ) : null }
                { options ? (
                    <Col className='crd-adm-head-col'>
                        {options}
                    </Col>
                ) : null }
                <Col span={24}>
                    {children}
                </Col>
            </Row>
        </div>
    )

}

export default CardAdminComponent;
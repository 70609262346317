// react libraries
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Checkbox, Col, Drawer, Form, Image, Input, InputNumber, List, Modal, Radio, Row, Spin, Switch, Typography, Upload, message } from "antd";

// services
import { POST_API, POST_CATCH, PageDefaultProps, UPLOAD_API, getToken } from "../../../services";

// components
import LoadItemComponent from "../../../components/LoadItemComponent";
import CardAdminComponent from "../../../components/CardAdminComponent";
import PageDefaultComponent from "../../../components/PageDefaultComponent";

// buttons components
import { TableReturnButton } from "../../../components/TableComponent/buttons";

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { FaCheck } from "react-icons/fa";
import { IoClose, IoEyeOutline, IoTrashOutline } from "react-icons/io5";
import { PlusOutlined } from '@ant-design/icons';
import { CircleMarker, MapContainer, TileLayer } from "react-leaflet"
import "leaflet/dist/leaflet.css";

const OrdensDeServicoExecution = ( { type, path, permission } : PageDefaultProps ) => {
    
    // route responsible
    const navigate = useNavigate()

    // params 
    const { ID } = useParams()

    // state
    const [ load, setLoad ] = useState(true)
    const [ loadSend, setLoadSend ] = useState(false)
    const [ loadQuestion, setLoadQuestion ] = useState<boolean>(false)
    const [ question, setQuestion ] = useState<any[]>([])
    const [ photo, setPhoto ] = useState<any[]>([])

    const [ loadProdutos, setLoadProdutos ] = useState(true)
    const [ loadProdutosSelect, setLoadProdutosSelect ] = useState(true)
    const [ open, setOpen ] = useState<boolean>(false)
    const [ search, setSearch ] = useState<string>('')
    const [ companion, setCompanion] = useState<string>('')
    const [ qty, setQty] = useState<any>(0)
    const [ produtos, setProdutos ] = useState<any[]>([])
    const [ produtosSelected, setProdutosSelected ] = useState<any[]>([])
    
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState<any>(null);

    // form
    const [ formQuestion ] = Form.useForm()
    const [ data, setData ] = useState<any>(null)

    // valid new or edit
    useEffect(() => {
        onLoadQuestion()
        onLoadPhotos()
        if (type === 'add') { 
            setLoad(false)
        } else {
            setLoad(true)
            onView()
        }
    }, [type, path, formQuestion, ID]);

    const onView = () => {
        setLoad(true)
        POST_API(`/${path}/search.php`, { token: getToken(), filter: JSON.stringify({ id: ID }), type }).then(rs => rs.json()).then(res => {
            if (res.return) {
                setData(res.data)
                setProdutosSelected(res.data.produtos)
                setCompanion(res.data.companion)
                setQty(res.data.qty)
                formQuestion.setFieldsValue(res.data.info)
            } else { Modal.warning({ title: 'Algo deu errado', content: res.msg }) }
        }).catch(POST_CATCH).finally( () => {setLoad(false);setLoadProdutosSelect(false)})
    }

    const onLoadPhotos = () => {
        POST_API(`/budget_photo/search.php`, { token: getToken(), filter: JSON.stringify({ budget_id: ID }), type: 'list' }).then(rs => rs.json()).then(res => {
            if (res.return) {
                setPhoto(res.data)
            } else {
                Modal.warning({ title: 'Algo deu errado', content: res.msg })
            }
        }).catch(POST_CATCH)
    }

    const onLoadQuestion = () => {
        setLoadQuestion(true)
        POST_API(`/question/search.php`, { token: getToken(), type: 'list' }).then(rs => rs.json()).then(res => {
            if (res.return) {
                setQuestion(res.data)
            } else {
                Modal.warning({ title: 'Algo deu errado', content: res.msg })
            }
        }).catch(POST_CATCH).finally( () => setLoadQuestion(false))
    }

    const onSend = (values:any, service:any) => {
        
        setLoadSend(true)
        POST_API(`/budget_services_report/save.php`, { token: getToken(), master: JSON.stringify({ services_id: service, budget_id: ID, info_report: values }) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success(res.msg)
                onView()
            } else { Modal.warning({ title: 'Algo deu errado', content: res.msg }) }
        }).catch(POST_CATCH).finally( () => setLoadSend(false) )
        
    }

    const changePresent = (value:any, id:any) => {
        
        POST_API(`/budget_user/save.php`, { token: getToken(), master: JSON.stringify({ ID: id, was_present: value }) }).then(rs => rs.json()).then(res => {
            if (res.return) { } else { Modal.warning({ title: 'Algo deu errado', content: res.msg }) }
        }).catch(POST_CATCH)

    }

    const onFinish = () => {

        if (!navigator.geolocation) { Modal.warning({ title: 'Ops', content:'Seu navegador não suporta geolocalização' }) }
        navigator.geolocation.getCurrentPosition((position) => {
            Modal.confirm({
                title: 'Você tem certeza?', content: 'Você irá finalizar esta ordem de serviço!', icon: <ExclamationCircleOutlined />, cancelText: 'Não, desisti!', okText: 'Sim, finalizar!',
                onOk() {
                    POST_API(`/${path}/save.php`, { token: getToken(), master: JSON.stringify({ ID: ID, status_execution: 'finished', position: position.coords }), type: 'edit' }).then(rs => rs.json()).then(res => {
                        if (res.return) {
                            message.success({ content: res.msg, key: 'screen' })
                            navigate('..')
                        } else {
                            Modal.warning({ title: 'Algo deu errado', content: res.msg })
                        }
                    }).catch(POST_CATCH)
                },
                onCancel() {},
            })
        }, () => Modal.warning({ title: 'Algo deu errado', content: 'Erro ao rastrear localização' }))

    }

    const onLoadProdutos = () => {
        setLoadProdutos(true)
        POST_API(`/product/search.php`, { token: getToken(), pagination: JSON.stringify({current: 1, page: 10}), sorter: JSON.stringify({selectColumn: 'product.name', order: 'ASC'}), search, type: 'list' }).then(rs => rs.json()).then(res => {
            if (res.return) {
                setProdutos(res.data)
            } else {
                Modal.warning({ title: 'Algo deu errado', content: res.msg })
            }
        }).catch(POST_CATCH).finally( () => setLoadProdutos(false))
    }

    const cancelProdutos = (index: any) => {
        setLoadProdutosSelect(true)
        var temp = produtosSelected;
        temp.splice(index, 1)
        setProdutosSelected(temp)
        setTimeout(() => {
            setLoadProdutosSelect(false)
        }, 1);
    }

    const changeService = (value:boolean , index: any, field: any) => {
        setLoadProdutosSelect(true)
        var temp = produtosSelected;
        temp[index][field] = value
        setProdutosSelected(temp)
        setTimeout(() => {
            setLoadProdutosSelect(false)
        }, 1);
    }

    const onSendProd = () => {
        setLoadSend(true)
        POST_API(`/${path}/save.php`, { token: getToken(), master: JSON.stringify({ ID: ID, produtos: produtosSelected }) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success(res.msg)
                onView()
            } else { Modal.warning({ title: 'Algo deu errado', content: res.msg }) }
        }).catch(POST_CATCH).finally( () => setLoadSend(false) )
    }

    const saveCompanion = () => {
        setLoadSend(true)
        POST_API(`/${path}/save.php`, { token: getToken(), master: JSON.stringify({ ID: ID, companion: companion }) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success(res.msg)
                onView()
            } else { Modal.warning({ title: 'Algo deu errado', content: res.msg }) }
        }).catch(POST_CATCH).finally( () => setLoadSend(false) )
    }

    const saveQty = () => {
        setLoadSend(true)
        POST_API(`/${path}/save.php`, { token: getToken(), master: JSON.stringify({ ID: ID, qty: qty }) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success(res.msg)
                onView()
            } else { Modal.warning({ title: 'Algo deu errado', content: res.msg }) }
        }).catch(POST_CATCH).finally( () => setLoadSend(false) )
    }

    const handleChange = ({ fileList }:any) => {

        if (!navigator.geolocation) { Modal.warning({ title: 'Ops', content:'Seu navegador não suporta geolocalização' }) }
        navigator.geolocation.getCurrentPosition((position) => {
            if ( fileList[0]?.response?.return ) {
                POST_API(`/budget_photo/save.php`, { token: getToken(), master: JSON.stringify({ budget_id: ID, url: fileList[0]?.response?.url, lat: position.coords.latitude, log: position.coords.longitude }) }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        message.success(res.msg)
                        onLoadPhotos()
                    } else { Modal.warning({ title: 'Algo deu errado', content: res.msg }) }
                }).catch(POST_CATCH).finally( () => setLoadSend(false) )
            }
        }, () => Modal.warning({ title: 'Algo deu errado', content: 'Erro ao rastrear localização' }))

    }

    const photoDelete = ( id:any ) => {
        Modal.confirm({
            title: 'Deletar foto?', icon: <ExclamationCircleOutlined />, cancelText: 'Não', okText: 'Sim',
            onOk() {
                POST_API(`/budget_photo/del.php`, { token: getToken(), id: id, type: 'del' }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        message.success({ content: res.msg, key: 'screen' })
                        onLoadPhotos()
                    } else {
                        Modal.warning({ title: 'Algo deu errado', content: res.msg })
                    }
                }).catch(POST_CATCH)
            },
            onCancel() {},
        })
    }

    const photoPreview = ( item:any ) => {      
        setPreviewImage(item);
        setPreviewOpen(true);
    }

    useEffect(() => {
        onLoadProdutos()
    }, [search])

    useEffect(() => {
        setOpen(false)
    }, [produtosSelected])

    return (
        <PageDefaultComponent title="Gerenciamento de Ordens de Serviço" items={[
            { title: 'Execução' },
        ]}>
            <Row gutter={[20,20]}>
                <Col md={24} xs={24}>
                    { load ? <LoadItemComponent /> :
                        <Row gutter={[16,16]}>
                            <Col md={24} xs={24}>
                                <CardAdminComponent title="Ordem de Serviço" subtitle={'Detalhes'} options={
                                    <Row justify={'end'} gutter={[8,8]}>
                                        <TableReturnButton type={type} permission={permission} />
                                    </Row>
                                }>
                                    <Row gutter={[8,8]}>
                                        <Col xs={24} md={2}>
                                            <Typography className="ord-title">N° O.S.:</Typography>
                                            <Typography className="ord-value">#{data.id}</Typography>
                                        </Col>
                                        <Col xs={24} md={17}>
                                            <Typography className="ord-title">Nome cliente:</Typography>
                                            <Typography className="ord-value">{data.client_name}</Typography>
                                        </Col>
                                        <Col xs={24} md={5}>
                                            <Typography className="ord-title">Data Prevista para Execução:</Typography>
                                            <Typography className="ord-value">{data.scheduled_execution_date ? data.scheduled_execution_date_format : '-'}</Typography>
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <Typography className="ord-title">Endereço:</Typography>
                                            <Typography className="ord-value">{data.client_address}</Typography>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Typography className="ord-title">E-mail:</Typography>
                                            <Typography className="ord-value">{ data.client_email ? data.client_email : '-' }</Typography>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Typography className="ord-title">Celular:</Typography>
                                            <Typography className="ord-value">{ data.client_cellphone ? data.client_cellphone : '-' }</Typography>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Typography className="ord-title">Telefone:</Typography>
                                            <Typography className="ord-value">{ data.client_telephone ? data.client_telephone : '-' }</Typography>
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <Typography className="ord-title">Observações:</Typography>
                                            <Typography className="ord-value">{ data.description ? data.description : '-' }</Typography>
                                        </Col>
                                    </Row>
                                </CardAdminComponent>
                            </Col>
                            <Col md={24} xs={24}>
                                <CardAdminComponent title="Ordem de Serviço" subtitle={'Prestadores para execução'}>
                                        <List
                                            size="small"
                                            locale={{emptyText: 'Nenhum prestador encontrado'}}
                                            dataSource={data.prestadores}
                                            renderItem={(item:any, index) => (
                                                <List.Item key={item.id}>
                                                    <List.Item.Meta title={item.name} description={item.user_type_name} />
                                                    <Switch defaultChecked={Boolean(Number(item.was_present))} onChange={(v) => changePresent(v, item.budget_user_id)} unCheckedChildren="Não presente" checkedChildren="Presente" style={{marginRight: 10}} />
                                                </List.Item>
                                            )}
                                        />
                                </CardAdminComponent>
                            </Col>
                            <Col md={24} xs={24}>
                                <CardAdminComponent title="Ordem de Serviço" subtitle={'Informações adicionais'}>
                                    <Form layout="vertical" form={formQuestion} disabled={true}>
                                        <List
                                            dataSource={question}
                                            loading={loadQuestion}
                                            locale={{emptyText: 'Nenhuma informação adicional encontrada'}}
                                            renderItem={(item:any, index) => (
                                                <List.Item key={item.id}>
                                                    <Form.Item name={`question#${item.id}`} label={item.question}  style={{width: '100%', margin: 0}}>
                                                    { item.type_question === 'open' ? <Input.TextArea rows={4} readOnly style={{width: '100%'}} /> : 
                                                        item.type_question === 'close' ? <Radio.Group> { item.answers.map((v:any, i:any) => <Radio value={v} key={i}>{v}</Radio> ) } </Radio.Group> :
                                                        item.type_question === 'multiple' ? <Checkbox.Group> { item.answers.map((v:any, i:any) => <Checkbox value={v} key={i}>{v}</Checkbox> ) } </Checkbox.Group> : null }
                                                    </Form.Item>
                                                </List.Item>
                                            )}
                                        />
                                    </Form>
                                </CardAdminComponent>
                            </Col>
                            <Col xs={24} md={24}>
                                <CardAdminComponent title="Ordem de Serviço" subtitle={'Acompanhante responsável'}>
                                    <Input value={companion} onChange={(v) => setCompanion(v.target.value)} placeholder="Nome do acompanhante responsável" addonAfter={<Button type="text" size="small" onClick={saveCompanion}>Salvar</Button>} />
                                </CardAdminComponent>
                            </Col>
                            <Col xs={24} md={24}>
                                <CardAdminComponent title="Ordem de Serviço" subtitle={'Filtros trocados (se necessário)'}>
                                    <Input type="number" value={qty} onChange={(v) => setQty(v.target.value)} placeholder="Quantidade filtros trocados" addonAfter={<Button type="text" size="small" onClick={saveQty}>Salvar</Button>} />
                                </CardAdminComponent>
                            </Col>
                            <Col xs={24} md={24}>
                                <CardAdminComponent title="Ordem de Serviço" subtitle={'Produtos utilizados'}>
                                    <List
                                        header={
                                            <Row justify={'space-between'} align={'middle'}>
                                                <Col><Typography style={{fontWeight: 600}}>Produtos selecionados</Typography></Col>
                                                <Col><Button onClick={() => setOpen(true)} type="primary" className="btn-primary" shape="round" size="small">Selecionar novo</Button></Col>
                                            </Row>
                                        }
                                        size="small"
                                        bordered
                                        locale={{emptyText: 'Nenhum produto selecionado'}}
                                        dataSource={produtosSelected}
                                        loading={loadProdutosSelect}
                                        renderItem={(item, index) => (
                                            <List.Item key={item.id}>
                                                <Row gutter={[8,8]} style={{width: '100%'}}>
                                                    <Col xs={24} md={14}>
                                                        <Row>
                                                            <IoClose style={{marginRight: 5}} size={20} className="list-check" onClick={() => cancelProdutos(index)} />
                                                            <List.Item.Meta title={`Produto #${index+1}`} description={<Input value={item.name} readOnly style={{width: '100%'}} />} />
                                                        </Row>
                                                    </Col>
                                                    <Col xs={12} md={5}>
                                                        <List.Item.Meta title={'Dosagem'} description={<InputNumber max={item.stock} min={0} defaultValue={item.dosage} style={{width: '100%'}} addonAfter={item.unit_measurement_acronym} onChange={(v) => changeService(v, index, 'dosage')} />} />
                                                    </Col>
                                                    <Col xs={12} md={5}>
                                                        <List.Item.Meta title={'Quantidade aplicada'} description={<InputNumber min={0} defaultValue={item.applied} style={{width: '100%'}} addonAfter={item.unit_measurement_qtd_acronym} onChange={(v) => changeService(v, index, 'applied')} />} />
                                                    </Col>
                                                </Row>
                                            </List.Item>
                                        )}
                                    />
                                    <Button onClick={onSendProd} shape="round" type="default" className="btn-default" size="small" style={{float: 'right', marginTop: 8}} loading={loadSend}>Salvar</Button>
                                </CardAdminComponent>
                            </Col>
                            <Col xs={24} md={24}>
                                <CardAdminComponent title="Ordem de Serviço" subtitle={'Fotos da operação'}>
                                    <Row gutter={[8,8]}>
                                        { photo.map((v:any, i:any) => (
                                            <Col>
                                                <div className="gallery-card">
                                                    <Image preview={false} src={`${v.url}`} />
                                                    <div className="gallery-filter"> </div>
                                                    <Row className="gallery-icons" gutter={[8,8]}>
                                                        <Col><IoEyeOutline onClick={() => photoPreview(v)} className="gallery-icon" /></Col>
                                                        <Col><IoTrashOutline onClick={() => photoDelete(v.id)} className="gallery-icon" /></Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        )) }
                                        <Col>
                                            <Upload
                                                action={UPLOAD_API}
                                                listType="picture-card"
                                                maxCount={1}
                                                showUploadList={false}
                                                onChange={handleChange}
                                            >
                                                <button style={{ border: 0, background: 'none' }} type="button">
                                                    <PlusOutlined />
                                                    <div style={{ marginTop: 8 }}>Upload</div>
                                                </button>
                                            </Upload>
                                        </Col>
                                        { previewImage && (
                                            <Image
                                                wrapperStyle={{ display: 'none' }}
                                                preview={{
                                                    destroyOnClose: true,
                                                    visible: previewOpen,
                                                    onVisibleChange: (visible) => setPreviewOpen(visible),
                                                    afterOpenChange: (visible) => !visible && setPreviewImage(''),
                                                    toolbarRender: () => null,
                                                    imageRender: () => {

                                                        return (
                                                            <div>
                                                                <Image style={{maxHeight: '100vh'}} src={`${previewImage.url}`} preview={false} />
                                                                <div className="image-coord">
                                                                    <Row justify={'space-between'} style={{padding: 10, flexWrap: 'nowrap'}}>
                                                                        <Col>
                                                                            <Typography className="image-coord-date">{previewImage.created_at_format}</Typography>
                                                                            <Typography className="image-coord-resp">{previewImage.created_name}</Typography>
                                                                            <Typography className="image-coord-city">{previewImage.city}</Typography>
                                                                            <Typography className="image-coord-coord">{previewImage.coord}</Typography>
                                                                        </Col>
                                                                        <Col>
                                                                            <MapContainer center={[Number(previewImage.lat), Number(previewImage.log)]} zoom={13} scrollWheelZoom={true} style={{width:'120px',height:'100px'}} >
                                                                                <TileLayer
                                                                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' 
                                                                                    url="http://tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                                                />
                                                                                <CircleMarker center={[Number(previewImage.lat), Number(previewImage.log)]} pathOptions={{ color: 'green'}} radius={10}></CircleMarker>
                                                                            </MapContainer>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </div>
                                                        )
                                                            
                                                    }
                                                } }
                                                src={previewImage}
                                            />
                                        )}
                                    </Row>
                                </CardAdminComponent>
                            </Col>
                            { data.services.map((v:any, i:number) => v.need_report === '1' ? (
                                <Col md={24} xs={24}>
                                    <CardAdminComponent title={v.name} subtitle={'Relatório'}>
                                        <Form layout="vertical" initialValues={v.info_report} onFinish={(values) => onSend(values, v.id)}>
                                            <List
                                                dataSource={v.report}
                                                locale={{emptyText: 'Nenhuma informação adicional encontrada'}}
                                                renderItem={(item:any, index) => (
                                                    <List.Item key={item.id}>
                                                        <Form.Item name={`question#${item.id}`} label={item.question}  style={{width: '100%', margin: 0}}>
                                                        { item.type_question === 'open' ? <Input.TextArea rows={4} style={{width: '100%'}} /> : 
                                                            item.type_question === 'close' ? <Radio.Group> { item.answers.map((v:any, i:any) => <Radio value={v} key={i}>{v}</Radio> ) } </Radio.Group> :
                                                            item.type_question === 'multiple' ? <Checkbox.Group> { item.answers.map((v:any, i:any) => <Checkbox value={v} key={i}>{v}</Checkbox> ) } </Checkbox.Group> : null }
                                                        </Form.Item>
                                                    </List.Item>
                                                )}
                                            />
                                            <Button htmlType="submit" shape="round" type="default" className="btn-default" size="small" style={{float: 'right'}} loading={loadSend}>Salvar</Button>
                                        </Form>
                                    </CardAdminComponent>
                                </Col>
                            ) : null )}
                            <Col span={24}>
                                <Button shape="round" onClick={onFinish} className="btn-primary" type="primary" style={{float: 'right', marginLeft: 6}} loading={loadSend}>Finalizar Ordem de Serviço</Button>
                                <Button shape="round" className="btn-default" type="default" style={{float: 'right'}} onClick={() => navigate('..')}>Cancelar</Button>
                            </Col>
                        </Row>
                    }
                </Col>
                <Drawer open={open} onClose={() => setOpen(false)} title="Selecionar produto" destroyOnClose>
                    <List
                        header={ <Row justify={'space-between'} align={'middle'}> <Col span={24}><Input onChange={(v) => setSearch(v.target.value)} placeholder="Pesquise aqui"/></Col> </Row> }
                        size="small"
                        locale={{emptyText: loadProdutos ? <Spin/> : 'Nenhum produto encontrado'}}
                        dataSource={produtos}
                        renderItem={(item) => (
                            <List.Item key={item.id}>
                                <List.Item.Meta title={item.name} description={item.user_type_name} />
                                <FaCheck className="list-check" onClick={() => setProdutosSelected([...produtosSelected, {...item, dosage: 0, applied: 0}])} />
                            </List.Item>
                        )}
                    />
                </Drawer>
            </Row>
        </PageDefaultComponent>
    )

}

export default OrdensDeServicoExecution;
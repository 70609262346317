// BIBLIOTECAS REACT
import { StyleSheet } from "@react-pdf/renderer";
import { Modal } from "antd";

// URL
const CONF_URL = "https://nlgsolutions.com.br";

var PATH = '';

export const setPath = (value: string) => { PATH = value }
export const getPath = () => { return PATH }

export const URL_API = CONF_URL+'/services/php';
export const UPLOAD_API = CONF_URL+'/services/php/upload/upload.php';

export const DEV:number = 0;
export const ADM:number = 1;
export const GRN:number = 2;
export const OPR:number = 3;
export const REP:number = 4;
export const CLN:number = 5;

export const ANTINPUT = "ant-input css-1r287do css-dev-only-do-not-override-1r287do ant-input-outlined";

export const POST_CATCH = () => {
    Modal.error({ title: 'Erro crítico!', content: 'Não foi possível estabelecer uma conexão com o servidor. Por favor, entre em contato com o suporte!' })
}

export const BLOCK_FORM_ENTER = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
        e.preventDefault();
    }
}

export const getUPLOADAPI = () => {
    return UPLOAD_API+'?path='+getPath()+'&token='+getToken();
}

export const setToken = (value : string) => {
    localStorage.setItem('TOKEN', value);
}

export const getToken = () => {
    return localStorage.getItem('TOKEN');
}

export const delToken = () => {
    localStorage.removeItem('TOKEN')
}

export const setConfig = (value: string) => {
    localStorage.setItem('CONFIG', value);
}

export const getConfig = (): any => {
    return Number(localStorage.getItem('CONFIG'));
}

export const delConfig = () => {
    localStorage.removeItem('CONFIG')
}

export const POST_API = (url: string, data: any) => {

    function createFormData () {
        const form = new FormData()
    
        Object.keys(data).forEach((key) => {
            form.append(key, data[key]);
        });
      
        return form;
    };
  
    return fetch(URL_API+url, { method: "post", body: createFormData() })

}


// VALIDA PERMISSAO
export const verifyConfig = (value: number[]) => {
    try {
        return value.includes(getConfig())
    } catch (error) {
        return false;
    }
}

export function MaskCNPJ ( event: any ): any {

    var text = event.target.value;
    
    text = text.replace(/\D/g,"")
    text = text.replace(/(\d{2})(\d)/,"$1.$2")
    text = text.replace(/(\d{3})(\d)/,"$1.$2")
    text = text.replace(/(\d{3})(\d)/,"$1/$2")
    text = text.replace(/(\d{4})(\d{1,2})$/,"$1-$2")

    event.target.value = text;
    
}

export function MaskCPF ( event: any ): any {

    var text = event.target.value;
    
    text = text.replace(/\D/g,"")
    text = text.replace(/(\d{3})(\d)/,"$1.$2")
    text = text.replace(/(\d{3})(\d)/,"$1.$2")
    text = text.replace(/(\d{3})(\d{1,2})$/,"$1-$2")
    
    event.target.value = text;
    
}

export function MaskCEP ( event: any ): any {

    var text = event.target.value;
    
    text = text.replace(/\D/g,"")
    text = text.replace(/(\d{5})(\d)/,"$1-$2")
    
    event.target.value = text;    
    
}

export function MaskCodeResiduos ( event: any ): any {

    var text = event.target.value;
    
    text = text.replace(/\D/g,"")
    text = text.replace(/(\d{2})(\d)/,"$1 $2")
    text = text.replace(/(\d{2})(\d)/,"$1 $2")
    text = text.replace(/(\d{2})(\d)/,"$1 $2")
    
    event.target.value = text;
    
}

// PALETA DE COR
export const cor1 = 'rgb(243, 48, 153)'
export const cor2 = 'rgb(147, 39, 143)'
export const cor3 = 'rgb(53, 0, 153)'
export const cor4 = 'rgb(96, 21, 207)'
export const cor5 = 'rgb(165, 82, 207)'

// INTERFACE
export interface PageDefaultProps { type: 'list' | 'trash' | 'add' | 'edit' | 'view', path: string, permission: number[] }

// PDF STYLES
// Create styles
export const styles = StyleSheet.create({
    page: {  },
    section: { margin: 10, padding: 10, flexGrow: 1 },
    cpTitle: {
        textAlign: 'center',
        paddingVertical: 10,
        borderBottom: '1px solid #d1d1d1',
        fontSize: 12,
    },
    cpText: {
        paddingVertical: 4,
        borderBottom: '1px solid #d1d1d1',
        fontSize: 10
    },
    flText: {
        paddingTop: 20,
        fontSize: 14,
        textAlign: 'justify'
    },
    cpObs: {
        paddingVertical: 4,
        borderBottom: '1px solid #d1d1d1',
        fontSize: 10,
        textAlign: 'center'
    },
    cpSignature: {
        width: '35%',
        borderTop: '1px solid #000',
        marginTop: 80
    },
    title: {
        textAlign: 'center',
        textTransform: 'uppercase',
        fontSize: 14,
        marginBottom: 10
    },
    subtitle: {
        textAlign: 'left', marginVertical: 10
    }, 
    header: {
        flexDirection: 'row', 
        height: 20,
        margin: 10,
        padding: 10,
    },
    table: {
        border: '1px solid #d1d1d1',
        borderBottom: 'none',
        marginBottom: 4
    },
    
    rowtext: {
        flexDirection: 'row',
        borderBottom: '1px solid #d1d1d1',
        paddingHorizontal: 4,
        paddingBottom: 4
    },
    cardtitle_col2: {
        fontSize: 8,
        textTransform: 'uppercase',
        color: '#780403',
        width: '10%'
    },
    cardtitle_col3: {
        fontSize: 8,
        textTransform: 'uppercase',
        color: '#780403',
        width: '15%'
    },
    cardtitle_col4: {
        fontSize: 8,
        textTransform: 'uppercase',
        color: '#780403',
        width: '20%'
    },
    cardtitle_col5: {
        fontSize: 8,
        textTransform: 'uppercase',
        color: '#780403',
        width: '25%'
    },
    cardtitle_col6: {
        fontSize: 8,
        textTransform: 'uppercase',
        color: '#780403',
        width: '30%'
    },
    cardtitle_col8: {
        fontSize: 8,
        textTransform: 'uppercase',
        color: '#780403',
        width: '40%'
    },
    cardtitle_col11: {
        fontSize: 8,
        textTransform: 'uppercase',
        color: '#780403',
        width: '55%'
    },
    cardtitle_col13: {
        fontSize: 8,
        textTransform: 'uppercase',
        color: '#780403',
        width: '65%'
    },
    cardtitle_col20: {
        fontSize: 8,
        textTransform: 'uppercase',
        color: '#780403',
        width: '100%'
    },
    textCenter: { textAlign: 'center', },
    colorRed: { color: 'red', },
    row: { flexDirection: 'row', },
    col1: { width: '5%', paddingHorizontal: 3 },
    col2: { width: '10%', paddingHorizontal: 3 },
    col3: { width: '15%', paddingHorizontal: 3 },
    col4: { width: '20%', paddingHorizontal: 3 },
    col5: { width: '25%', paddingHorizontal: 3 },
    col6: { width: '30%', paddingHorizontal: 3 },
    col8: { width: '40%', paddingHorizontal: 3 },
    col11: { width: '55%', paddingHorizontal: 3 },
    col12: { width: '60%', paddingHorizontal: 3 },
    col13: { width: '65%', paddingHorizontal: 3 },
    col14: { width: '70%', paddingHorizontal: 3 },
    col15: { width: '75%', paddingHorizontal: 3 },
    col16: { width: '80%', paddingHorizontal: 3 },
    col19: { width: '95%', paddingHorizontal: 3 },
    col20: { width: '100%', paddingHorizontal: 3 },
    borderRight: { borderRight: '1px solid #d1d1d1' }
});
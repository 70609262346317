// react libraries
import { ReactElement } from 'react';
import { Col, Row, Typography } from 'antd';

// components
import CardAdminComponent from '../CardAdminComponent';

// css
import './index.css';
import { IconType } from 'react-icons';

// interface
interface KpiInterface {
    title: string,
    subtitle: string,
    color: string,
    value: string,
    icon: ReactElement<IconType>
}

const KpiComponent = ( { title, subtitle, color, value, icon } : KpiInterface ) => {

    return (
        <CardAdminComponent title={title} subtitle={subtitle}>
            <Row align={'middle'} gutter={[16,16]}>
                <Col><div className='kpi-icon' style={{ color: color, backgroundColor: `${color}14` }}>{icon}</div></Col>
                <Col><Typography className='kpi-value'>{value}</Typography></Col>
            </Row>
        </CardAdminComponent>
    )

}

export default KpiComponent;
// css
import './index.css';

// interface
interface CardInterface {
    children: any
}

const CardComponent = ( { children } : CardInterface ) => {

    return (
        <div className='crd'>
            {children}
        </div>
    )

}

export default CardComponent;
// react libraries
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Form, Input, Modal, Row, Select, message } from "antd";

// services
import { ADM, GRN, OPR, POST_API, POST_CATCH, PageDefaultProps, REP, getToken } from "../../../services";

// components
import LoadItemComponent from "../../../components/LoadItemComponent";
import CardAdminComponent from "../../../components/CardAdminComponent";
import PageDefaultComponent from "../../../components/PageDefaultComponent";

// buttons components
import { TableReturnButton } from "../../../components/TableComponent/buttons";

const UsuariosForm = ( { type, path, permission } : PageDefaultProps ) => {

    // route responsible
    const navigate = useNavigate()
    
    // params 
    const { ID } = useParams()

    // state
    const [ load, setLoad ] = useState(true)
    const [ loadButton, setLoadButton ] = useState(false)

    // form
    const [form] = Form.useForm()

    // valid new or edit
    useEffect(() => {
        if (type === 'add') { setLoad(false) } else {
            setLoad(true)
            POST_API(`/${path}/search.php`, { token: getToken(), filter: JSON.stringify({ id: ID }), type }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    form.setFieldsValue(res.data)
                } else { Modal.warning({ title: 'Algo deu errado', content: res.msg }) }
            }).catch(POST_CATCH).finally( () => setLoad(false))
        }
    }, [type, path, form, ID]);

    // function save
    const onSend = (values: any) => {
        setLoadButton(true)
        values.ID = ID
        POST_API(`/${path}/save.php`, { token: getToken(), master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success(res.msg)
                navigate('..')
            } else { Modal.warning({ title: 'Algo deu errado', content: res.msg }) }
        }).catch(POST_CATCH).finally( () => setLoadButton(false) )
    }

    return (
        <PageDefaultComponent title="Gerenciamento de Usuários" items={[
            { title: type === 'add' ? 'Novo registro' : type === 'view' ? 'Visualizar registro' : 'Editar registro' },
        ]}>
            <Row gutter={[20,20]}>
                <Col md={24} xs={24}>
                    { load ? <LoadItemComponent /> :
                        <CardAdminComponent title="Usuários" subtitle={type === 'add' ? 'Novo registro' : type === 'view' ? 'Visualizar registro' : 'Editar registro'} options={
                            <Row justify={'end'} gutter={[8,8]}>
                                <TableReturnButton type={type} permission={permission} />
                            </Row>
                        }>
                            <Form id="formass" disabled={type === 'view'} layout="vertical" onFinish={onSend} form={form}>
                                <Row gutter={[8,0]}>
                                    <Col xs={24} md={6}>
                                        <Form.Item name="user_type" label="Tipo de acesso" rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                            <Select placeholder="Tipo de acesso">
                                                <Select.Option value={ADM}>Administrador do sistema</Select.Option>
                                                <Select.Option value={GRN}>Gerente</Select.Option>
                                                <Select.Option value={OPR}>Operador de serviços</Select.Option>
                                                <Select.Option value={REP}>Representante</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={18}>
                                        <Form.Item name="name" label="Nome" rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                            <Input placeholder="Nome" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={10}>
                                        <Form.Item name="login" label="Login" rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                            <Input placeholder="Login" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={4}>
                                        <Form.Item name="status" label="Status" rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                            <Select placeholder="Status">
                                                <Select.Option value={'A'}>Ativo</Select.Option>
                                                <Select.Option value={'I'}>Inativo</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    { type === 'view' ? null :
                                        <Col span={24}>
                                            <Button shape="round" htmlType="submit" className="btn-primary" type="primary" style={{float: 'right', marginLeft: 6}} loading={loadButton}>Salvar</Button>
                                            <Button shape="round" className="btn-default" type="default" style={{float: 'right'}} onClick={() => navigate('..')}>Cancelar</Button>
                                        </Col>
                                    }
                                </Row>
                            </Form>
                        </CardAdminComponent>
                    }
                </Col>
            </Row>
        </PageDefaultComponent>
    )

}

export default UsuariosForm;
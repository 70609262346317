// react libraries
import { useEffect, useState } from "react";
import { Col, Modal, Popover, Row, Tag, Typography } from "antd";

// components
import KpiComponent from "../../../components/KpiComponent";
import PageDefaultComponent from "../../../components/PageDefaultComponent";
import CardAdminComponent from "../../../components/CardAdminComponent";

// css
import './index.css';
import { IoDocumentTextOutline, IoPeopleOutline } from "react-icons/io5";
import { TbCurrencyDollar } from "react-icons/tb";
import { ADM, CLN, GRN, OPR, POST_API, POST_CATCH, REP, getToken, verifyConfig } from "../../../services";
import LoadComponent from "../../../components/LoadComponent";
import TableComponent from "../../../components/TableComponent";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {

    // route
    const navigate = useNavigate()

    // states
    const [ action, setAction ] = useState<boolean>(false)
    const [ data, setData ] = useState<any>(null)

    // function load dash
    const onLoad = () => {

        setData(null)
        POST_API(`/dashboard/search.php`, { token: getToken() }).then(rs => rs.json()).then(res => {
            if (res.return) {
                setData(res.data)
            } else { Modal.warning({ title: 'Algo deu errado', content: res.msg }) }
        }).catch(POST_CATCH)

    }

    // table columns
    const column = [
        { title: 'Código', dataIndex: 'id', table: 'budget.id', width: '100px', sorter: true, align: 'center', render: null },
        { title: 'Previsão Execução', dataIndex: 'scheduled_execution_date_format', table: 'budget.scheduled_execution_date', width: '160px', sorter: true, align: 'center', render: null },
        { title: 'Execução', dataIndex: 'started_execution_at_format', table: 'budget.started_execution_at', width: '160px', sorter: true, align: 'center', render: (item:any) => (
            <Row align={'middle'} style={{width: '100%', flexDirection: 'column'}}>
                <Popover title="Execução iniciada" content={`Por ${item.started_name}`}><Typography style={{color: '#ff9807', fontWeight: 600}}>{item.started_execution_at ? item.started_execution_at_format : null}</Typography></Popover>
                <Popover title="Execução finalizada" content={`Por ${item.finished_name}`}><Typography style={{color: '#a52834', fontWeight: 600}}>{item.finished_execution_at ? item.finished_execution_at_format : null}</Typography></Popover>
            </Row>
        ) },
        { title: 'Cliente', dataIndex: 'client_name', table: 'client.name', width: 'auto', minWidth: '240px', sorter: true, align: 'left', render: null },
        { title: 'Valor', dataIndex: 'total_name', table: '( budget.price - budget.discount )', width: '140px', sorter: true, align: 'right', render: null },
        { title: 'Status', dataIndex: 'status', width: '180px', sorter: false, align: 'center', render: (item:any) => ( <Row justify={'center'} style={{width: '100%'}}><Tag style={{margin: 0}} color={item.status_execution_color}>{item.status_execution_name}</Tag></Row> ) },
    ]

    useEffect(() => {
        if (verifyConfig([ADM,GRN])) {
            onLoad()
        } else if (verifyConfig([OPR])) {
            navigate('/sistema/ordensdeservico')
        } else if (verifyConfig([REP])) {
            navigate('/sistema/clientes')
        } else if (verifyConfig([CLN])) {
            navigate('/sistema/meuperfil')
        }
    }, [])

    return (
        <PageDefaultComponent title="Dashboard" items={[
            { title: 'Dashboard' },
        ]}>
            <Row gutter={[20,20]}>
                { data === null ? <Col span={24}><LoadComponent /></Col> : (
                    <>
                        <Col md={8} xs={24}>
                            <KpiComponent title="O.S. (Abertas)" subtitle="Hoje" icon={<IoDocumentTextOutline />} color="#4154f1" value={data.budgets}/>
                        </Col>
                        <Col md={8} xs={24}>
                            <KpiComponent title="Faturamento" subtitle="Neste mês" icon={<TbCurrencyDollar />} color="#2eca6a" value={data.total}/>
                        </Col>
                        <Col md={8} xs={24}>
                            <KpiComponent title="Clientes" subtitle="Cadastrados" icon={<IoPeopleOutline />} color="#ff771d" value={data.clients}/>
                        </Col>
                        <Col md={24} xs={24}>
                            <CardAdminComponent title="Ordem de Serviços" subtitle="Abertas">
                                <TableComponent 
                                    column={column}
                                    path={'budget'}
                                    type={'list'}
                                    action={action}
                                    defaultFilter={{ status: 'approved', technical: true, finished: true }}
                                />
                            </CardAdminComponent>
                        </Col>
                        <Col md={24} xs={24}>
                            <CardAdminComponent title="Certificados vencendo" subtitle="Hoje">
                                <></>
                            </CardAdminComponent>
                        </Col>
                    </>
                ) }
            </Row>
        </PageDefaultComponent>
    )

}

export default Dashboard;
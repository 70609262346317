// react libraries
import React from 'react';
import ReactDOM from 'react-dom/client';

// components
import App from './routes';

// css
import './index.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "leaflet/dist/leaflet.css";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

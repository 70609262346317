// react libraries
import { Page, Text, View } from '@react-pdf/renderer';

// services
import { styles } from "../../../../../services";

const OrdensDeServicoFilesCertificadoControlePragas = ( { data } : any ) => {


    return (
        <>
            <Page size="A4" style={styles.page}>
                {/* HEADER */}
                <View style={styles.section}>
                    <Text style={styles.title}>Certificado</Text>
                    <View style={styles.table}>
                        <View style={styles.row}>
                            <Text style={{...styles.col20, ...styles.cpTitle}}>1º Identificação do Local Tratado</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col5, ...styles.cpText, ...styles.borderRight}}>Nome/Razão Social:</Text>
                            <Text style={{...styles.col15, ...styles.cpText}}>{data.client_name}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col5, ...styles.cpText, ...styles.borderRight}}>Endereço:</Text>
                            <Text style={{...styles.col15, ...styles.cpText}}>{data.client_address}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col5, ...styles.cpText, ...styles.borderRight}}>{String(data.client_cpf_cnpj).length > 14 ? 'CNPJ' : 'CPF'}</Text>
                            <Text style={{...styles.col15, ...styles.cpText}}>{data.client_cpf_cnpj}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col5, ...styles.cpText, ...styles.borderRight}}>CEP:</Text>
                            <Text style={{...styles.col15, ...styles.cpText}}>{data.client_cep}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col20, ...styles.cpTitle}}>2º Execução do Serviço</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col5, ...styles.cpText, ...styles.borderRight}}>Data e Hora:</Text>
                            <Text style={{...styles.col15, ...styles.cpText}}>{data.started_execution_at_format} à {data.finished_execution_at_format}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col5, ...styles.cpText, ...styles.borderRight}}>Coleta de Lixo</Text>
                            <Text style={{...styles.col15, ...styles.cpText}}>Sim ( ) Não ( )</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col5, ...styles.cpText, ...styles.borderRight}}>Área tratada</Text>
                            <Text style={{...styles.col15, ...styles.cpText}}>Ambiente Externo</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col20, ...styles.cpTitle}}>3º Praga Alvo</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col4, ...styles.cpText, ...styles.borderRight}}>Baratas</Text>
                            <Text style={{...styles.col1, ...styles.cpText, ...styles.borderRight, ...styles.textCenter}}> { (data.pragas.filter((item:any) => item.id === '1')).length > 0 ? 'X' : '' } </Text>
                            <Text style={{...styles.col4, ...styles.cpText, ...styles.borderRight}}>Roedores</Text>
                            <Text style={{...styles.col1, ...styles.cpText, ...styles.borderRight, ...styles.textCenter}}> { (data.pragas.filter((item:any) => item.id === '2')).length > 0 ? 'X' : '' } </Text>
                            <Text style={{...styles.col4, ...styles.cpText, ...styles.borderRight}}>Cupins</Text>
                            <Text style={{...styles.col1, ...styles.cpText, ...styles.borderRight, ...styles.textCenter}}> { (data.pragas.filter((item:any) => item.id === '3')).length > 0 ? 'X' : '' } </Text>
                            <Text style={{...styles.col4, ...styles.cpText, ...styles.borderRight}}>Formigas</Text>
                            <Text style={{...styles.col1, ...styles.cpText, ...styles.textCenter}}> { (data.pragas.filter((item:any) => item.id === '4')).length > 0 ? 'X' : '' } </Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col4, ...styles.cpText, ...styles.borderRight}}>Aranhas</Text>
                            <Text style={{...styles.col1, ...styles.cpText, ...styles.borderRight, ...styles.textCenter}}>{ (data.pragas.filter((item:any) => item.id === '5')).length > 0 ? 'X' : '' } </Text>
                            <Text style={{...styles.col4, ...styles.cpText, ...styles.borderRight}}>Mosquitos</Text>
                            <Text style={{...styles.col1, ...styles.cpText, ...styles.borderRight, ...styles.textCenter}}> { (data.pragas.filter((item:any) => item.id === '6')).length > 0 ? 'X' : '' } </Text>
                            <Text style={{...styles.col4, ...styles.cpText, ...styles.borderRight}}>Traças</Text>
                            <Text style={{...styles.col1, ...styles.cpText, ...styles.borderRight, ...styles.textCenter}}> { (data.pragas.filter((item:any) => item.id === '7')).length > 0 ? 'X' : '' } </Text>
                            <Text style={{...styles.col4, ...styles.cpText, ...styles.borderRight}}>Escopiões</Text>
                            <Text style={{...styles.col1, ...styles.cpText, ...styles.textCenter}}> { (data.pragas.filter((item:any) => item.id === '8')).length > 0 ? 'X' : '' } </Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col20, ...styles.cpTitle}}>4º Produtos Utilizados</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col1, ...styles.cpText, ...styles.borderRight}}></Text>
                            <Text style={{...styles.col4, ...styles.cpText, ...styles.borderRight}}>Descrição</Text>
                            <Text style={{...styles.col4, ...styles.cpText, ...styles.borderRight}}>Dosagem</Text>
                            <Text style={{...styles.col3, ...styles.cpText, ...styles.borderRight}}>Veículo</Text>
                            <Text style={{...styles.col4, ...styles.cpText, ...styles.borderRight}}>Qtde. Aplicada</Text>
                            <Text style={{...styles.col4, ...styles.cpText}}>Alvo</Text>
                        </View>
                        { data.produtos && data.produtos.map((v:any, i:any) => (
                            <View style={styles.row}>
                                <Text style={{...styles.col1, ...styles.cpText, ...styles.borderRight, ...styles.textCenter}}>{i+1}</Text>
                                <Text style={{...styles.col4, ...styles.cpText, ...styles.borderRight}}>{v.name}</Text>
                                <Text style={{...styles.col4, ...styles.cpText, ...styles.borderRight}}>{v.dosage_name}</Text>
                                <Text style={{...styles.col3, ...styles.cpText, ...styles.borderRight}}>-</Text>
                                <Text style={{...styles.col4, ...styles.cpText, ...styles.borderRight}}>{v.applied_name}</Text>
                                <Text style={{...styles.col4, ...styles.cpText}}>-</Text>
                            </View>
                        )) }

                        <View style={styles.row}>
                            <Text style={{...styles.col20, ...styles.cpTitle}}>5º Indicações para uso Médico</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col15, ...styles.cpText, ...styles.borderRight}}>Ação Tóxica do Produto</Text>
                            <Text style={{...styles.col5, ...styles.cpText}}>Grupo</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col15, ...styles.cpText, ...styles.borderRight}}></Text>
                            <Text style={{...styles.col5, ...styles.cpText}}></Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col20, ...styles.cpObs, ...styles.colorRed}}>Obs: Em caso de intoxicação pelo produto, entrar em contato com o centro de informações Toxicológicas 0800 6464350 ou 0800 112273.</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col20, ...styles.cpTitle}}>Observações Complementares</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col20, ...styles.cpObs}}>Monitoramento durante o período de Assistência Técnica Garantida</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col20, ...styles.cpObs, ...styles.colorRed}}>Atenção: Este serviço necessita ser realizado mensalmente.</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col20, ...styles.cpTitle}}>Medidas de segurança</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col20, ...styles.cpObs}}>Alertamos ao clientes que os produtos químicos usados nos serviços de controle e combate a insetos e outros animais nocivos são tóxicos, porém, quando usados sob orientação técnica e dentro dos padrões permitidos, o risco de intoxicação trona-se bastante reduzido.</Text>
                        </View>
                    </View>
                </View>
            </Page>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <View style={styles.table}>
                        <View style={styles.row}>
                            <Text style={{...styles.col20, ...styles.cpTitle}}>INSETOS</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col20, ...styles.cpText}}>ANTES DO TRATAMENTO</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col20, ...styles.cpText}}>• Proteger os alimentos, louças e utensílios domésticos, guardanapos em recipientes com tampa ou cobrindo-os com plástico.</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col20, ...styles.cpText}}>DURANTE O TRATAMENTO</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col20, ...styles.cpText}}>• Não permitir a presença de pessoas no local.</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col20, ...styles.cpText}}>APÓS O TRATAMENTO</Text>
                        </View>
                        <View style={{...styles.row, flexDirection: 'column'}}>
                            <Text style={{...styles.col20, ...styles.cpText, borderBottom: 'none'}}>• Antes de ocupar novamente o recinto, abrir as janelas para arejar o ambiente.</Text>
                            <Text style={{...styles.col20, ...styles.cpText, borderBottom: 'none'}}>• Aguardar no mínimo 06 horas para permitir o ingresso de pessoas e animais.</Text>
                            <Text style={{...styles.col20, ...styles.cpText, borderBottom: 'none'}}>• Crianças, pessoas idosas e alérgicas deverão observar um prazo maior.</Text>
                            <Text style={{...styles.col20, ...styles.cpText}}>• Aguardar 48 horas para limpar o local tratado.</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col20, ...styles.cpTitle}}>ROEDORES</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col20, ...styles.cpText}}>• Proteger as iscas envenenadas do acesso de crianças e animais domésticos.</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col20, ...styles.cpTitle}}>OBSERVAÇÕES</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col20, ...styles.cpText}}>{data.description ? data.description : '-'}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{width: '33%', ...styles.cpText, ...styles.textCenter, ...styles.borderRight}}>Químico Responsável</Text>
                            <Text style={{width: '33%', ...styles.cpText, ...styles.textCenter, ...styles.borderRight}}>Aplicador</Text>
                            <Text style={{width: '34%', ...styles.cpText, ...styles.textCenter}}>Acompanhante Responsável</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{width: '33%', ...styles.cpText, ...styles.textCenter, ...styles.borderRight}}>Diego Neves Lopes Galvão</Text>
                            <Text style={{width: '33%', ...styles.cpText, ...styles.textCenter, ...styles.borderRight}}>{data.finished_name}</Text>
                            <Text style={{width: '34%', ...styles.cpText, ...styles.textCenter}}>{data.companion}</Text>
                        </View>
                    </View>
                    <View style={{...styles.cpSignature}}>
                        <Text style={{...styles.textCenter, fontSize: 10}}>NLG SOLUTIONS</Text>
                        <Text style={{...styles.textCenter, fontSize: 10}}>CNPJ: 24.534.437/0001-73</Text>
                    </View>
                </View>
            </Page>
        </>
    )

}

export default OrdensDeServicoFilesCertificadoControlePragas;
// react libraries
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Modal, Row, Typography } from "antd";

// services
import { POST_API, POST_CATCH, PageDefaultProps, getToken } from "../../../services";

// components
import LoadItemComponent from "../../../components/LoadItemComponent";
import CardAdminComponent from "../../../components/CardAdminComponent";
import PageDefaultComponent from "../../../components/PageDefaultComponent";

// buttons components
import { TableReturnButton } from "../../../components/TableComponent/buttons";
import { TbCertificate, TbFileTypePdf, TbFileTypeXls } from "react-icons/tb";

const OrdensDeServicoFiles = ( { type, path, permission } : PageDefaultProps ) => {
    
    // route responsible
    const navigate = useNavigate()

    // params 
    const { ID } = useParams()

    // state
    const [ load, setLoad ] = useState(true)
    // form
    const [ data, setData ] = useState<any>(null)

    // valid new or edit
    useEffect(() => {
        if (type === 'add') { 
            setLoad(false)
        } else {
            setLoad(true)
            POST_API(`/${path}/search.php`, { token: getToken(), filter: JSON.stringify({ id: ID }), type }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    setData(res.data)
                } else { Modal.warning({ title: 'Algo deu errado', content: res.msg }) }
            }).catch(POST_CATCH).finally( () => {setLoad(false)})
        }
    }, [type, path, ID]);

    return (
        <PageDefaultComponent title="Gerenciamento de Ordens de Serviço" items={[
            { title: 'Visualizar registro' },
        ]}>
            <Row gutter={[20,20]}>
                <Col md={24} xs={24}>
                    { load ? <LoadItemComponent /> :
                        <Row gutter={[16,16]}>
                            <Col md={24} xs={24}>
                                <CardAdminComponent title="Ordem de Serviço" subtitle={'Documentos'} options={
                                    <Row justify={'end'} gutter={[8,8]}>
                                        <TableReturnButton type={type} permission={permission} />
                                    </Row>
                                }>
                                    <Row gutter={[8,8]}>
                                        <Col>
                                            <div className="button-report disabled">
                                                <TbFileTypePdf className="button-report-icon" />
                                                <Typography className="button-report-text">Relatório O.S.</Typography>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="button-report disabled">
                                                <TbFileTypeXls className="button-report-icon" />
                                                <Typography className="button-report-text">Planilha de Monitoramento</Typography>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="button-report disabled">
                                                <TbFileTypeXls className="button-report-icon" />
                                                <Typography className="button-report-text">Controle de Execução</Typography>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardAdminComponent>
                            </Col>
                            { data.services.map((v:any, i:number) => v.need_certificate === '1' || v.need_report === '1' || v.has_technical_visit === '1' ? (
                                <Col md={24} xs={24} key={i}>
                                    <CardAdminComponent title={v.name} subtitle={'Documentos'}>
                                        <Row gutter={[8,8]}>
                                            { v.need_certificate === '1' ? 
                                                <Col>
                                                    <div className="button-report" onClick={() => navigate(`certificado/${v.id}`)}>
                                                        <TbCertificate className="button-report-icon" />
                                                        <Typography className="button-report-text">Certificado</Typography>
                                                    </div>
                                                </Col> 
                                            : null }
                                            { v.need_report === '1' ? 
                                                <Col>
                                                    <div className="button-report disabled">
                                                        <TbFileTypePdf className="button-report-icon" />
                                                        <Typography className="button-report-text">Relatório</Typography>
                                                    </div>
                                                </Col> 
                                            : null }
                                            { v.has_technical_visit === '1' ? 
                                                <Col>
                                                    <div className="button-report disabled">
                                                        <TbFileTypePdf className="button-report-icon" />
                                                        <Typography className="button-report-text">Visita Técnica</Typography>
                                                    </div>
                                                </Col> 
                                            : null }
                                            
                                        </Row>
                                    </CardAdminComponent>
                                </Col>
                            ) : (
                                <Col md={24} xs={24} key={i}>
                                    <CardAdminComponent title={v.name} subtitle={'Documentos'}>
                                        <Typography>Sem documentos</Typography>
                                    </CardAdminComponent>
                                </Col>
                            ) )}
                        </Row>
                    }
                </Col>
            </Row>
        </PageDefaultComponent>
    )

}

export default OrdensDeServicoFiles;
// react libraries
import { Breadcrumb, Col, Divider, Row, Typography } from "antd";
import { Link, useNavigate } from "react-router-dom";

// componentes
import LoadComponent from "../LoadComponent";

// icons
import { RiHome3Line } from "react-icons/ri";

// css
import './styles.css'; 


// INTERFACE
interface PageDefaultComponentInterface {
    title: any,
    children?: any,
    items: any[],
    options?: any,
    valid?: any
}

const PageDefaultComponent = ( { title, children, items, options, valid } : PageDefaultComponentInterface ) => {

    // responsible for the route
    const navigate = useNavigate()

    if (true) {
        return (
            <Row className="page-default-page">
                <Col span={24} style={{padding: '1em', paddingBottom: '0em'}}>
                    <Row justify={'space-between'} align={'top'}>
                        <Col><Typography className="page-default-title">{title}</Typography><Breadcrumb className="page-default-breadcrumb" items={[ { title: <RiHome3Line size={20} className="page-default-link" onClick={() => navigate('/sistema/dashboard')} /> }, ...items]} /></Col>
                        <Col>{options}</Col>
                    </Row>
                </Col>
                <Col span={24} style={{marginTop: 16, padding: '1em', paddingTop: '0em'}}>
                    {children}
                    <Divider />
                    <Typography className="page-default-text">© Copyright <b>NLG Solutions</b>. Todos os direitos reservados.</Typography>
                    <Typography className="page-default-text">Desenvolvido por <b><Link to="https://adsolucoestecnologia.com.br/" target="_blank">AD Soluções em Tecnologia</Link></b></Typography>
                </Col>
            </Row>
        )
    } else {
        return <LoadComponent title="Você não tem permissão para acessar essa página"/>
    }

}

export default PageDefaultComponent;
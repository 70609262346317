// react libraries
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Modal, Row } from "antd";
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';

// services
import { POST_API, POST_CATCH, PageDefaultProps, getToken, styles } from "../../../../services";

// components
import LoadItemComponent from "../../../../components/LoadItemComponent";
import PageDefaultComponent from "../../../../components/PageDefaultComponent";
import CardAdminComponent from "../../../../components/CardAdminComponent";
import { TableReturnButton } from "../../../../components/TableComponent/buttons";
import OrdensDeServicoFilesCertificadoControlePragas from "./certificados/controlePragas";
import OrdensDeServicoFilesCertificadoFiltros from "./certificados/Filtros";
import OrdensDeServicoFilesCertificadoHigienizacao from "./certificados/Higienizacao";


const OrdensDeServicoFilesCertificado = ( { type, path, permission } : PageDefaultProps ) => {

    // params 
    const { ID, CERTIFICATE } = useParams()

    // state
    const [ load, setLoad ] = useState(true)

    // form
    const [ cert, setCert ] = useState<any>(null)
    const [ data, setData ] = useState<any>(null)

    // valid new or edit
    useEffect(() => {
        if (type === 'add') { 
            setLoad(false)
        } else {
            setLoad(true)
            POST_API(`/${path}/search.php`, { token: getToken(), filter: JSON.stringify({ id: ID }), type }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    setData(res.data)
                } else { Modal.warning({ title: 'Algo deu errado', content: res.msg }) }
            }).catch(POST_CATCH).finally( () => {setLoad(false)})
        }
    }, [type, path, ID]);

    useEffect(() => {
        POST_API(`/services/search.php`, { token: getToken(), filter: JSON.stringify({ id: CERTIFICATE }), type }).then(rs => rs.json()).then(res => {
            if (res.return) {
                setCert(res.data)
            } else { Modal.warning({ title: 'Algo deu errado', content: res.msg }) }
        }).catch(POST_CATCH)
    }, []);

    return (
        <PageDefaultComponent title="Gerenciamento de Ordens de Serviço" items={[
            { title: `Certificado - ${cert?.certificate_name}` },
        ]}>
            <Row gutter={[20,20]}>
                <Col md={24} xs={24}>
                    { load ? <LoadItemComponent /> :
                        <Row gutter={[16,16]}>
                            <Col md={24} xs={24}>
                                <CardAdminComponent title={`Certificado`} subtitle={`${cert?.certificate_name}`} options={
                                    <Row justify={'end'} gutter={[8,8]}>
                                        <TableReturnButton type={type} permission={permission} />
                                    </Row>
                                }>
                                    <PDFViewer style={{width: '100%', height: 700}}>
                                        <Document>
                                            { CERTIFICATE === '11' ? <OrdensDeServicoFilesCertificadoControlePragas data={data} /> : null }
                                            { CERTIFICATE === '8' ? <OrdensDeServicoFilesCertificadoFiltros data={data} /> : null }
                                            { CERTIFICATE === '10' ? <OrdensDeServicoFilesCertificadoHigienizacao data={data} /> : null }
                                        </Document>
                                    </PDFViewer>
                                </CardAdminComponent>
                            </Col>
                        </Row>
                    }
                </Col>
            </Row>
        </PageDefaultComponent>
    )

}

export default OrdensDeServicoFilesCertificado;
// react libraries
import { Page, Text, View } from '@react-pdf/renderer';

// services
import { styles } from "../../../../../services";

const OrdensDeServicoFilesCertificadoHigienizacao = ( { data } : any ) => {    

    return (
        <>
            <Page size="A4" style={styles.page}>
                {/* HEADER */}
                <View style={styles.section}>
                    <Text style={styles.title}>CERTIFICADO - HIGIENIZAÇÃO DE RESERVATÓRIO DE ÁGUA</Text>
                    <View style={styles.table}>
                        <View style={styles.row}>
                            <Text style={{...styles.col20, ...styles.cpTitle}}>IDENTIFICAÇÃO DO LOCAL TRATADO</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col5, ...styles.cpText, ...styles.borderRight}}>Nome/Razão Social:</Text>
                            <Text style={{...styles.col15, ...styles.cpText}}>{data.client_name}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col5, ...styles.cpText, ...styles.borderRight}}>Endereço:</Text>
                            <Text style={{...styles.col15, ...styles.cpText}}>{data.client_address}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col5, ...styles.cpText, ...styles.borderRight}}>{String(data.client_cpf_cnpj).length > 14 ? 'CNPJ' : 'CPF'}</Text>
                            <Text style={{...styles.col15, ...styles.cpText}}>{data.client_cpf_cnpj}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col5, ...styles.cpText, ...styles.borderRight}}>CEP:</Text>
                            <Text style={{...styles.col15, ...styles.cpText}}>{data.client_cep}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col20, ...styles.cpTitle}}>EXECUÇÃO DO SERVIÇO</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col4, ...styles.cpText, ...styles.textCenter, ...styles.borderRight}}>Data e Hora:</Text>
                            <Text style={{...styles.col16, ...styles.cpText, ...styles.textCenter}}>{data.started_execution_at_format} à {data.finished_execution_at_format}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col4, ...styles.cpText, ...styles.textCenter, ...styles.borderRight}}>Caixa d'água Volume em Litros:</Text>
                            <Text style={{...styles.col4, ...styles.cpText, ...styles.textCenter, ...styles.borderRight}}>Caixa n°1: {'\n'} N.A. </Text>
                            <Text style={{...styles.col4, ...styles.cpText, ...styles.textCenter, ...styles.borderRight}}>Caixa n°2: {'\n'} N.A. </Text>
                            <Text style={{...styles.col4, ...styles.cpText, ...styles.textCenter, ...styles.borderRight}}>Caixa n°3: {'\n'} N.A. </Text>
                            <Text style={{...styles.col4, ...styles.cpText, ...styles.textCenter}}>Caixa n°4: {'\n'} N.A. </Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col20, ...styles.cpTitle}}>PROCESSO REALIZADO</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col1, ...styles.cpText, ...styles.textCenter, ...styles.borderRight}}>1º</Text>
                            <Text style={{...styles.col19, ...styles.cpText, ...styles.borderRight}}>
                                Desinfecção de torres e reservatórios de água conforme portaria CVS-13 de 30/07/98.
                                Padrão 3860 da CETESB e NBR 13.245 para efetivações do serviço. {'\n'}
                                Registro Ministério da Saúde sob 3.1243.0001. Limpeza mecânica ou hidrojateamento com
                                solução bactericida nas paredes, fundos, bicos de alimentação e saída.
                            </Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col20, ...styles.cpTitle}}>PRODUTO UTILIZADO PARA DESINFECÇÃO</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col1, ...styles.cpText, ...styles.textCenter, ...styles.borderRight}}>2º</Text>
                            <Text style={{...styles.col19, ...styles.cpText, ...styles.borderRight}}>
                                HIDROSAN PLUS EFERVESCENTE: são pastilhas de Dicloroisocianurato de Sódio
                                concentrado a 2,7% para que se obtenha dosagem de cloro (NaClo2), conforme orientação
                                dos Orgãos e Institutos de análise para a destruição dos germes patogênicos e células
                                bacterianas (inclusive vibrião colérico). {'\n'}
                                Hidrosan Plus é um produto utilizado pelo Ministério da Saúde,utilizados na prevenção de
                                doenças graves transmitidas pela água e pelos alimentos contaminados. Esta
                                Contaminação se dá através de bactérias, vírus, protozoários, fungos e vermes. Hidrosan
                                Plus proporciona dosagens precisas de cloro, sem deixar resíduos na água ou nos
                                alimentos.Hidrosan Plus elimina os parasitas causadores de doenças de forma segura,
                                rápida e eficaz. {'\n'}
                                Por ser efervescente dissolve-se totalmente sem deixar resíduos, homogeneizando melhor
                                a solução.
                            </Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col20, ...styles.cpTitle}}>OBSERVAÇÕES COMPLEMENTARES</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col20, ...styles.cpText}}>{data.description ? data.description : '-'}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col20, ...styles.cpText}}>Próxima limpeza: {data.services.filter((v:any) => v.id === '10')[0].due_date}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{width: '33%', ...styles.cpText, ...styles.textCenter, ...styles.borderRight}}>Químico Responsável</Text>
                            <Text style={{width: '33%', ...styles.cpText, ...styles.textCenter, ...styles.borderRight}}>Aplicador</Text>
                            <Text style={{width: '34%', ...styles.cpText, ...styles.textCenter}}>Acompanhante Responsável</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{width: '33%', ...styles.cpText, ...styles.textCenter, ...styles.borderRight}}>Diego Neves Lopes Galvão</Text>
                            <Text style={{width: '33%', ...styles.cpText, ...styles.textCenter, ...styles.borderRight}}>{data.finished_name}</Text>
                            <Text style={{width: '34%', ...styles.cpText, ...styles.textCenter}}>{data.companion}</Text>
                        </View>
                    </View>
                    <View style={{...styles.cpSignature}}>
                        <Text style={{...styles.textCenter, fontSize: 10}}>NLG SOLUTIONS</Text>
                        <Text style={{...styles.textCenter, fontSize: 10}}>CNPJ: 24.534.437/0001-73</Text>
                    </View>
                </View>
            </Page>
        </>
    )

}

export default OrdensDeServicoFilesCertificadoHigienizacao;
// react libraries
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Modal, Row } from "antd";
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';

// services
import { POST_API, POST_CATCH, PageDefaultProps, getToken, styles } from "../../../../services";

// components
import LoadItemComponent from "../../../../components/LoadItemComponent";
import PageDefaultComponent from "../../../../components/PageDefaultComponent";
import CardAdminComponent from "../../../../components/CardAdminComponent";
import { TableReturnButton } from "../../../../components/TableComponent/buttons";


const OrdensDeServicoFilesRelatorio = ( { type, path, permission } : PageDefaultProps ) => {

    // params 
    const { ID } = useParams()

    // state
    const [ load, setLoad ] = useState(true)

    // form
    const [ data, setData ] = useState<any>(null)

    // valid new or edit
    useEffect(() => {
        if (type === 'add') { 
            setLoad(false)
        } else {
            setLoad(true)
            POST_API(`/${path}/search.php`, { token: getToken(), filter: JSON.stringify({ id: ID }), type }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    setData(res.data)
                } else { Modal.warning({ title: 'Algo deu errado', content: res.msg }) }
            }).catch(POST_CATCH).finally( () => {setLoad(false)})
        }
    }, [type, path, ID]);

    return (
        <PageDefaultComponent title="Gerenciamento de Ordens de Serviço" items={[
            { title: 'Relatório' },
        ]}>
            <Row gutter={[20,20]}>
                <Col md={24} xs={24}>
                    { load ? <LoadItemComponent /> :
                        <Row gutter={[16,16]}>
                            <Col md={24} xs={24}>
                                <CardAdminComponent title="Relatório" subtitle={'O.S.'} options={
                                    <Row justify={'end'} gutter={[8,8]}>
                                        <TableReturnButton type={type} permission={permission} />
                                    </Row>
                                }>
                                    <PDFViewer style={{width: '100%', height: 700}}>
                                        <Document>
                                            <Page size="A4" style={styles.page}>
                                                {/* HEADER */}
                                                {/* <View style={styles.section}>
                                                    <Text style={styles.title}>Relatório O.S.</Text>
                                                    <View style={styles.table}>
                                                        <View style={styles.row}>
                                                            <Text style={styles.cardtitle_col2}>Nº O.S.:</Text>
                                                            <Text style={styles.cardtitle_col13}>Nome cliente:</Text>
                                                            <Text style={styles.cardtitle_col5}>Data prevista para execução:</Text>
                                                        </View>
                                                        <View style={styles.rowtext}>
                                                            <Text style={styles.cardtext_col2}>#{data.id}</Text>
                                                            <Text style={styles.cardtext_col13}>{data.client_name}</Text>
                                                            <Text style={styles.cardtext_col5}>{data.scheduled_execution_date ? data.scheduled_execution_date_format : '-'}</Text>
                                                        </View>
                                                        <View style={styles.row}>
                                                            <Text style={styles.cardtitle_col20}>Endereco:</Text>
                                                        </View>
                                                        <View style={styles.rowtext}>
                                                            <Text style={styles.cardtext_col20}>{data.client_address}</Text>
                                                        </View>
                                                        <View style={styles.row}>
                                                            <Text style={styles.cardtitle_col8}>E-mail:</Text>
                                                            <Text style={styles.cardtitle_col6}>Celular:</Text>
                                                            <Text style={styles.cardtitle_col6}>Telefone:</Text>
                                                        </View>
                                                        <View style={styles.rowtext}>
                                                            <Text style={styles.cardtext_col8}>{ data.client_email ? data.client_email : '-' }</Text>
                                                            <Text style={styles.cardtext_col6}>{ data.client_cellphone ? data.client_cellphone : '-' }</Text>
                                                            <Text style={styles.cardtext_col6}>{ data.client_telephone ? data.client_telephone : '-' }</Text>
                                                        </View>
                                                        <View style={styles.row}>
                                                            <Text style={styles.cardtitle_col20}>Observações:</Text>
                                                        </View>
                                                        <View style={styles.rowtext}>
                                                            <Text style={styles.cardtext_col20}>{data.description ? data.description : '-'}</Text>
                                                        </View>
                                                        <View style={styles.row}>
                                                            <Text style={styles.cardtitle_col11}>Forma de pagamento:</Text>
                                                            <Text style={styles.cardtitle_col3}>Valor:</Text>
                                                            <Text style={styles.cardtitle_col3}>Desconto:</Text>
                                                            <Text style={styles.cardtitle_col3}>Total a pagar:</Text>
                                                        </View>
                                                        <View style={styles.rowtext}>
                                                            <Text style={styles.cardtext_col11}>{ data.payment_method_id ? data.payment_method_name : '-' }</Text>
                                                            <Text style={styles.cardtext_col3}>{ data.price ? data.price_name : '-' }</Text>
                                                            <Text style={styles.cardtext_col3}>{ data.discount ? data.discount_name : '-' }</Text>
                                                            <Text style={styles.cardtext_col3}>{ data.total ? data.total_name : '-' }</Text>
                                                        </View>
                                                    </View>
                                                    <Text style={styles.subtitle}>Serviços</Text>
                                                    { data.services.map((v:any, i:number) => (
                                                        <View style={styles.table} key={i}>
                                                            <View style={styles.row}>
                                                                <Text style={styles.cardtitle_col20}>Serviço {i+1}:</Text>
                                                            </View>
                                                            <View style={styles.rowtext}>
                                                                <Text style={styles.cardtext_col20}>{v.name ? v.name : '-'}</Text>
                                                            </View>
                                                            <View style={styles.row}>
                                                                <Text style={styles.cardtitle_col11}>Valor:</Text>
                                                                <Text style={styles.cardtitle_col3}>Visita Técnica:</Text>
                                                                <Text style={styles.cardtitle_col3}>Certificado:</Text>
                                                                <Text style={styles.cardtitle_col3}>Relatório:</Text>
                                                            </View>
                                                            <View style={styles.rowtext}>
                                                                <Text style={styles.cardtext_col11}>{ v.price_name ? v.price_name : '-' }</Text>
                                                                <Text style={styles.cardtext_col3}>{ v.has_technical_visit_name ? v.has_technical_visit_name : '-' }</Text>
                                                                <Text style={styles.cardtext_col3}>{ v.need_certificate_name ? v.need_certificate_name : '-' }</Text>
                                                                <Text style={styles.cardtext_col3}>{ v.need_report_name ? v.need_report_name : '-' }</Text>
                                                            </View>
                                                        </View>
                                                    ))}
                                                </View> */}
                                            </Page>
                                        </Document>
                                    </PDFViewer>
                                </CardAdminComponent>
                            </Col>
                        </Row>
                    }
                </Col>
            </Row>
        </PageDefaultComponent>
    )

}

export default OrdensDeServicoFilesRelatorio;
// react libraries
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";

// permissions
import { ADM, CLN, DEV, GRN, OPR, REP } from "./services";

// landing page
import LandingPage from "./app";

// login page
import Login from "./app/login";

// main page system
import Sistema from "./app/sistema";

// dashboard
import Dashboard from "./app/sistema/dashboard";

// user types
import Usuarios from "./app/sistema/usuarios";
import UsuariosForm from "./app/sistema/usuarios/form";
import Perfil from "./app/sistema/perfil";
import Fornecedores from "./app/sistema/fornecedores";
import FornecedoresForm from "./app/sistema/fornecedores/form";
import Clientes from "./app/sistema/clientes";
import ClientesForm from "./app/sistema/clientes/form";
import FormasDePagamento from "./app/sistema/formasdepagamento";
import FormasDePagamentoForm from "./app/sistema/formasdepagamento/form";
import Certificados from "./app/sistema/certificados";
import CertificadosForm from "./app/sistema/certificados/form";
import UnidadesDeMedida from "./app/sistema/unidadesdemedida";
import UnidadesDeMedidaForm from "./app/sistema/unidadesdemedida/form";
import Servicos from "./app/sistema/servicos";
import ServicosForm from "./app/sistema/servicos/form";
import Produtos from "./app/sistema/produtos";
import ProdutosForm from "./app/sistema/produtos/form";
import ContasAPagar from "./app/sistema/contasapagar";
import ContasAPagarForm from "./app/sistema/contasapagar/form";
import Questionario from "./app/sistema/questionario";
import QuestionarioForm from "./app/sistema/questionario/form";
import Orcamentos from "./app/sistema/orcamentos";
import OrcamentosForm from "./app/sistema/orcamentos/form";
import OrdensDeServico from "./app/sistema/ordernsdeservico";
import OrdensDeServicoForm from "./app/sistema/ordernsdeservico/form";
import ServicosVisitaTecnica from "./app/sistema/servicos/visitatecnica";
import ServicosVisitaTecnicaForm from "./app/sistema/servicos/visitatecnica/form";
import ServicosRelatorio from "./app/sistema/servicos/relatorio";
import ServicosRelatorioForm from "./app/sistema/servicos/relatorio/form";
import PragasAlvo from "./app/sistema/pragasalvo";
import PragasAlvoForm from "./app/sistema/pragasalvo/form";
import OrdensDeServicoView from "./app/sistema/ordernsdeservico/view";
import OrdensDeServicoVisita from "./app/sistema/ordernsdeservico/visita";
import OrdensDeServicoExecution from "./app/sistema/ordernsdeservico/execucao";
import OrdensDeServicoFiles from "./app/sistema/ordernsdeservico/files";
import OrdensDeServicoFilesRelatorio from "./app/sistema/ordernsdeservico/relatorio";
import OrdensDeServicoFilesCertificado from "./app/sistema/ordernsdeservico/relatorio/certificado";
import Cadastrese from "./app/cadastrese";

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                {/* landing page */}
                <Route path="" element={<LandingPage />} />
                <Route path="login" element={<Login />} />
                <Route path="cadastrese" element={<Cadastrese />} />
                <Route path="sistema" element={<Sistema />}>
                    <Route path="meuperfil" element={<Perfil />} />
                    <Route path="dashboard" element={<Dashboard />} />
                    <Route path="usuarios" element={<Outlet />}>
                        <Route path="" element={<Usuarios type="list" permission={[DEV,ADM,GRN]} path="user" />}/>
                        <Route path="novo" element={<UsuariosForm type="add" permission={[DEV,ADM,GRN]} path="user" />}/>
                        <Route path=":ID" element={<UsuariosForm type="view" permission={[DEV,ADM,GRN]} path="user" />}/>
                        <Route path=":ID/editar" element={<UsuariosForm type="edit" permission={[DEV,ADM,GRN]} path="user" />}/>
                    </Route>
                    <Route path="fornecedores" element={<Outlet />}>
                        <Route path="" element={<Fornecedores type="list" permission={[DEV,ADM,GRN]} path="provider" />}/>
                        <Route path="novo" element={<FornecedoresForm type="add" permission={[DEV,ADM,GRN]} path="provider" />}/>
                        <Route path=":ID" element={<FornecedoresForm type="view" permission={[DEV,ADM,GRN]} path="provider" />}/>
                        <Route path=":ID/editar" element={<FornecedoresForm type="edit" permission={[DEV,ADM,GRN]} path="provider" />}/>
                    </Route>
                    <Route path="clientes" element={<Outlet />}>
                        <Route path="" element={<Clientes type="list" permission={[DEV,ADM,GRN,REP]} path="client" />}/>
                        <Route path="novo" element={<ClientesForm type="add" permission={[DEV,ADM,GRN,REP]} path="client" />}/>
                        <Route path=":ID" element={<ClientesForm type="view" permission={[DEV,ADM,GRN,REP]} path="client" />}/>
                        <Route path=":ID/editar" element={<ClientesForm type="edit" permission={[DEV,ADM,GRN,REP]} path="client" />}/>
                    </Route>
                    <Route path="formasdepagamento" element={<Outlet />}>
                        <Route path="" element={<FormasDePagamento type="list" permission={[DEV,ADM,GRN]} path="payment_method" />}/>
                        <Route path="novo" element={<FormasDePagamentoForm type="add" permission={[DEV,ADM,GRN]} path="payment_method" />}/>
                        <Route path=":ID" element={<FormasDePagamentoForm type="view" permission={[DEV,ADM,GRN]} path="payment_method" />}/>
                        <Route path=":ID/editar" element={<FormasDePagamentoForm type="edit" permission={[DEV,ADM,GRN]} path="payment_method" />}/>
                    </Route>
                    <Route path="certificados" element={<Outlet />}>
                        <Route path="" element={<Certificados type="list" permission={[DEV,ADM,GRN]} path="certificates" />}/>
                        <Route path="novo" element={<CertificadosForm type="add" permission={[DEV,ADM,GRN]} path="certificates" />}/>
                        <Route path=":ID" element={<CertificadosForm type="view" permission={[DEV,ADM,GRN]} path="certificates" />}/>
                        <Route path=":ID/editar" element={<CertificadosForm type="edit" permission={[DEV,ADM,GRN]} path="certificates" />}/>
                    </Route>
                    <Route path="unidadesdemedida" element={<Outlet />}>
                        <Route path="" element={<UnidadesDeMedida type="list" permission={[DEV,ADM,GRN]} path="unit_measurement" />}/>
                        <Route path="novo" element={<UnidadesDeMedidaForm type="add" permission={[DEV,ADM,GRN]} path="unit_measurement" />}/>
                        <Route path=":ID" element={<UnidadesDeMedidaForm type="view" permission={[DEV,ADM,GRN]} path="unit_measurement" />}/>
                        <Route path=":ID/editar" element={<UnidadesDeMedidaForm type="edit" permission={[DEV,ADM,GRN]} path="unit_measurement" />}/>
                    </Route>
                    <Route path="servicos" element={<Outlet />}>
                        <Route path="" element={<Servicos type="list" permission={[DEV,ADM,GRN]} path="services" />}/>
                        <Route path="novo" element={<ServicosForm type="add" permission={[DEV,ADM,GRN]} path="services" />}/>
                        <Route path=":ID" element={<ServicosForm type="view" permission={[DEV,ADM,GRN]} path="services" />}/>
                        <Route path=":ID/editar" element={<ServicosForm type="edit" permission={[DEV,ADM,GRN]} path="services" />}/>
                        <Route path=":ID/visitatecnica" element={<Outlet />}>
                            <Route path="" element={<ServicosVisitaTecnica type="list" permission={[DEV,ADM,GRN]} path="services_technical_visit" />}/>
                            <Route path="novo" element={<ServicosVisitaTecnicaForm type="add" permission={[DEV,ADM,GRN]} path="services_technical_visit" />}/>
                            <Route path=":ID2" element={<ServicosVisitaTecnicaForm type="view" permission={[DEV,ADM,GRN]} path="services_technical_visit" />}/>
                            <Route path=":ID2/editar" element={<ServicosVisitaTecnicaForm type="edit" permission={[DEV,ADM,GRN]} path="services_technical_visit" />}/>
                        </Route>
                        <Route path=":ID/relatorio" element={<Outlet />}>
                            <Route path="" element={<ServicosRelatorio type="list" permission={[DEV,ADM,GRN]} path="services_report" />}/>
                            <Route path="novo" element={<ServicosRelatorioForm type="add" permission={[DEV,ADM,GRN]} path="services_report" />}/>
                            <Route path=":ID2" element={<ServicosRelatorioForm type="view" permission={[DEV,ADM,GRN]} path="services_report" />}/>
                            <Route path=":ID2/editar" element={<ServicosRelatorioForm type="edit" permission={[DEV,ADM,GRN]} path="services_report" />}/>
                        </Route>
                    </Route>
                    <Route path="produtos" element={<Outlet />}>
                        <Route path="" element={<Produtos type="list" permission={[DEV,ADM,GRN]} path="product" />}/>
                        <Route path="novo" element={<ProdutosForm type="add" permission={[DEV,ADM,GRN]} path="product" />}/>
                        <Route path=":ID" element={<ProdutosForm type="view" permission={[DEV,ADM,GRN]} path="product" />}/>
                        <Route path=":ID/editar" element={<ProdutosForm type="edit" permission={[DEV,ADM,GRN]} path="product" />}/>
                    </Route>
                    <Route path="pragasalvo" element={<Outlet />}>
                        <Route path="" element={<PragasAlvo type="list" permission={[DEV,ADM,GRN]} path="target_pest" />}/>
                        <Route path="novo" element={<PragasAlvoForm type="add" permission={[DEV,ADM,GRN]} path="target_pest" />}/>
                        <Route path=":ID" element={<PragasAlvoForm type="view" permission={[DEV,ADM,GRN]} path="target_pest" />}/>
                        <Route path=":ID/editar" element={<PragasAlvoForm type="edit" permission={[DEV,ADM,GRN]} path="target_pest" />}/>
                    </Route>
                    <Route path="contasapagar" element={<Outlet />}>
                        <Route path="" element={<ContasAPagar type="list" permission={[DEV,ADM,GRN]} path="bill_to_pay" />}/>
                        <Route path="novo" element={<ContasAPagarForm type="add" permission={[DEV,ADM,GRN]} path="bill_to_pay" />}/>
                        <Route path=":ID" element={<ContasAPagarForm type="view" permission={[DEV,ADM,GRN]} path="bill_to_pay" />}/>
                        <Route path=":ID/editar" element={<ContasAPagarForm type="edit" permission={[DEV,ADM,GRN]} path="bill_to_pay" />}/>
                    </Route>
                    <Route path="orcamentos" element={<Outlet />}>
                        <Route path="" element={<Orcamentos type="list" permission={[DEV,ADM,GRN, REP]} path="budget" />}/>
                        <Route path="novo" element={<OrcamentosForm type="add" permission={[DEV,ADM,GRN,CLN, REP]} path="budget" />}/>
                        <Route path=":ID" element={<OrcamentosForm type="view" permission={[DEV,ADM,GRN,CLN, REP]} path="budget" />}/>
                        <Route path=":ID/editar" element={<OrcamentosForm type="edit" permission={[DEV,ADM,GRN,CLN, REP]} path="budget" />}/>
                    </Route>
                    <Route path="ordensdeservico" element={<Outlet />}>
                        <Route path="" element={<OrdensDeServico type="list" permission={[DEV,ADM,GRN,OPR,CLN]} path="budget" />}/>
                        <Route path=":ID" element={<OrdensDeServicoView type="view" permission={[DEV,ADM,GRN,OPR,CLN]} path="budget" />}/>
                        <Route path=":ID/visita" element={<OrdensDeServicoVisita type="view" permission={[DEV,ADM,GRN,OPR,CLN]} path="budget" />}/>
                        <Route path=":ID/execucao" element={<OrdensDeServicoExecution type="view" permission={[DEV,ADM,GRN,OPR,CLN]} path="budget" />}/>
                        <Route path=":ID/documentos" element={<OrdensDeServicoFiles type="view" permission={[DEV,ADM,GRN,OPR,CLN]} path="budget" />}/>
                        <Route path=":ID/documentos/relatorio" element={<Outlet />}>
                            <Route path="" element={<OrdensDeServicoFilesRelatorio type="view" permission={[DEV,ADM,GRN,OPR,CLN]} path="budget" />} />
                        </Route>
                        <Route path=":ID/documentos/certificado/:CERTIFICATE" element={<Outlet />}>
                            <Route path="" element={<OrdensDeServicoFilesCertificado type="view" permission={[DEV,ADM,GRN,OPR,CLN]} path="budget" />} />
                        </Route>
                        <Route path=":ID/editar" element={<OrdensDeServicoForm type="edit" permission={[DEV,ADM,GRN,OPR,CLN]} path="budget" />}/>
                    </Route>
                    <Route path="questionario" element={<Outlet />}>
                        <Route path="" element={<Questionario type="list" permission={[DEV,ADM,GRN]} path="question" />}/>
                        <Route path="novo" element={<QuestionarioForm type="add" permission={[DEV,ADM,GRN]} path="question" />}/>
                        <Route path=":ID" element={<QuestionarioForm type="view" permission={[DEV,ADM,GRN]} path="question" />}/>
                        <Route path=":ID/editar" element={<QuestionarioForm type="edit" permission={[DEV,ADM,GRN]} path="question" />}/>
                    </Route>
                    <Route path="*" element={<></>} />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export default App;
// react libraries
import { Button, Col, Form, Input, Layout, Row, Typography, message } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';

// icons
import { IoAt, IoLockClosedOutline } from 'react-icons/io5';

// css
import './index.css';
import { POST_API, POST_CATCH, setConfig, setToken } from '../../services';

const Login = () => {

    // responsible route
    const navigate = useNavigate();

    // states
    const [ type, setType ] = useState<'' | 'cliente' | 'colaborador'>('');
    const [ load, setLoad ] = useState(false)

    // logo
    const logo = '../assets/img/logo.png';

    // login
    const onSend = (values: any) => {

        setLoad(true)
        POST_API('/user/login.php', { token: 'X', master: JSON.stringify(values), type }).then(rs => rs.json()).then(res => {
            if (res.return) {
                setToken(res.token)
                setConfig(res.config)
                navigate('/sistema/dashboard')
            } else {
                message.warning(res.msg)
            }
        }).catch(POST_CATCH).finally(() => {
            setLoad(false)
        })
        
    }

    return (
        <Layout>
            <Row className='st-row' justify={'center'} align={'middle'}>
                <Col> <img alt="NLG Solutions" src={logo} className='st-img' /> </Col>
                <Col>
                    <div className='st-login'>
                        <Typography className='st-title'>Login do sistema</Typography>
                        <Typography className='st-text'>{ type === '' ? 'Você é nosso:' : type === 'colaborador' ? 'Colaborador' : type === 'cliente' ? 'Cliente' : '-' } </Typography>
                        { type === '' ? (
                            <>
                                <Button onClick={() => setType('cliente')} block size='large' className='btn-primary' style={{marginBottom: '0.6em'}}>Cliente</Button>
                                <Button onClick={() => setType('colaborador')} block size='large' className='btn-default'>Colaborador</Button>
                            </>
                        ) : (
                            <Form layout='vertical' onFinish={onSend}>
                                { type === 'colaborador' ? (
                                    <Row>
                                        <Col span={24}>
                                            <Form.Item label="Login" name="login">
                                                <Input addonBefore={<IoAt />} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item label="Senha" name="password">
                                                <Input.Password addonBefore={<IoLockClosedOutline />} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Button htmlType='submit' block size='large' className='btn-primary'>Login</Button>
                                            <Button style={{marginTop: 10}} block size='small' className='btn-text' type='text' onClick={() => setType('')}>Voltar</Button>
                                        </Col>
                                    </Row>
                                ) : type === 'cliente' ? (
                                    <Row>
                                        <Col span={24}>
                                            <Form.Item label="E-mail" name="login">
                                                <Input addonBefore={<IoAt />} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item label="Senha" name="password">
                                                <Input.Password addonBefore={<IoLockClosedOutline />} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Button htmlType='submit' block size='large' className='btn-primary'>Login</Button>
                                        </Col>
                                        <Col span={24}>
                                            <Typography className='st-text-forgot'>Esqueceu seu usuário e/ou senha? <Link to={'/recuperar'}>Clique aqui!</Link></Typography>
                                        </Col>
                                    </Row>
                                ): null }
                            </Form>
                        ) }
                    </div>
                </Col>
                <Col> <Typography className='st-text'>Desenvolvido por <Link target="_blank" to="https://adsolucoestecnologia.com.br">AD Soluções em Tecnologia</Link></Typography> </Col>
            </Row>
        </Layout>
    )

}

export default Login;
// react libraries
import { Col, Row, Typography } from 'antd'
import { ThreeCircles } from 'react-loader-spinner'

// css
import './index.css'

// interface
interface LoadComponentInterface {
    title?: string,
}

const LoadComponent = ( { title = 'Aguarde' } : LoadComponentInterface ) => {

    return (
        <div className='load-item'>
            <Row justify={'center'} align={'middle'} style={{flexDirection: 'column'}}>
                <Col><Typography className='load-item-text'>{title}</Typography></Col>
                <Col>
                    <ThreeCircles
                        visible={true}
                        height="50"
                        width="50"
                        color="var(--color01)"
                        ariaLabel="grid-loading"
                        wrapperClass="grid-wrapper"
                    />
                </Col>
            </Row>
        </div>
    )

}

export default LoadComponent
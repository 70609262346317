// react libraries
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Checkbox, Col, Form, Input, List, Modal, Radio, Row, Switch, Typography, message } from "antd";

// services
import { POST_API, POST_CATCH, PageDefaultProps, getToken } from "../../../services";

// components
import LoadItemComponent from "../../../components/LoadItemComponent";
import CardAdminComponent from "../../../components/CardAdminComponent";
import PageDefaultComponent from "../../../components/PageDefaultComponent";

// buttons components
import { TableReturnButton } from "../../../components/TableComponent/buttons";

import { ExclamationCircleOutlined } from '@ant-design/icons';

const OrdensDeServicoView = ( { type, path, permission } : PageDefaultProps ) => {
    
    // route responsible
    const navigate = useNavigate()

    // params 
    const { ID } = useParams()

    // state
    const [ load, setLoad ] = useState(true)
    const [ loadSend, setLoadSend ] = useState(false)
    const [ loadQuestion, setLoadQuestion ] = useState<boolean>(false)
    const [ question, setQuestion ] = useState<any[]>([])
    
    // form
    const [ formQuestion ] = Form.useForm()
    const [ data, setData ] = useState<any>(null)

    // valid new or edit
    useEffect(() => {
        onLoadQuestion()
        if (type === 'add') { 
            setLoad(false)
        } else {
            setLoad(true)
            onView()
        }
    }, [type, path, formQuestion, ID]);

    const onView = () => {
        setLoad(true)
        POST_API(`/${path}/search.php`, { token: getToken(), filter: JSON.stringify({ id: ID }), type }).then(rs => rs.json()).then(res => {
            if (res.return) {
                setData(res.data)
                formQuestion.setFieldsValue(res.data.info)
            } else { Modal.warning({ title: 'Algo deu errado', content: res.msg }) }
        }).catch(POST_CATCH).finally( () => {setLoad(false)})
    }

    const onLoadQuestion = () => {
        setLoadQuestion(true)
        POST_API(`/question/search.php`, { token: getToken(), type: 'list' }).then(rs => rs.json()).then(res => {
            if (res.return) {
                setQuestion(res.data)
            } else {
                Modal.warning({ title: 'Algo deu errado', content: res.msg })
            }
        }).catch(POST_CATCH).finally( () => setLoadQuestion(false))
    }

    const onSend = (values:any, service:any) => {
        
        setLoadSend(true)
        POST_API(`/budget_services_technical_visit/save.php`, { token: getToken(), master: JSON.stringify({ services_id: service, budget_id: ID, info: values }) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success(res.msg)
                onView()
            } else { Modal.warning({ title: 'Algo deu errado', content: res.msg }) }
        }).catch(POST_CATCH).finally( () => setLoadSend(false) )
        
    }

    const changePresent = (value:any, id:any) => {
        
        POST_API(`/budget_user/save.php`, { token: getToken(), master: JSON.stringify({ ID: id, was_present: value }) }).then(rs => rs.json()).then(res => {
            if (res.return) { } else { Modal.warning({ title: 'Algo deu errado', content: res.msg }) }
        }).catch(POST_CATCH)

    }

    const onFinish = () => {

        Modal.confirm({
            title: 'Você tem certeza?', content: 'Você irá finalizar esta visita técnica', icon: <ExclamationCircleOutlined />, cancelText: 'Não, desisti!', okText: 'Sim, finalizar!',
            onOk() {
                setLoadSend(true)
                POST_API(`/budget_services/save.php`, { token: getToken(), master: JSON.stringify({ budget_id: ID }) }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        message.success(res.msg)
                        navigate('..')
                    } else { Modal.warning({ title: 'Algo deu errado', content: res.msg }) }
                }).catch(POST_CATCH).finally( () => setLoadSend(false) )
            },
            onCancel() {},
        })

    }

    return (
        <PageDefaultComponent title="Gerenciamento de Ordens de Serviço" items={[
            { title: 'Visita técnica' },
        ]}>
            <Row gutter={[20,20]}>
                <Col md={24} xs={24}>
                    { load ? <LoadItemComponent /> :
                        <Row gutter={[16,16]}>
                            <Col md={24} xs={24}>
                                <CardAdminComponent title="Ordem de Serviço" subtitle={'Detalhes'} options={
                                <Row justify={'end'} gutter={[8,8]}>
                                    <TableReturnButton type={type} permission={permission} />
                                </Row>
                                }>
                                    <Row gutter={[8,8]}>
                                        <Col xs={24} md={2}>
                                            <Typography className="ord-title">N° O.S.:</Typography>
                                            <Typography className="ord-value">#{data.id}</Typography>
                                        </Col>
                                        <Col xs={24} md={17}>
                                            <Typography className="ord-title">Nome cliente:</Typography>
                                            <Typography className="ord-value">{data.client_name}</Typography>
                                        </Col>
                                        <Col xs={24} md={5}>
                                            <Typography className="ord-title">Data Prevista para Execução:</Typography>
                                            <Typography className="ord-value">{data.scheduled_execution_date ? data.scheduled_execution_date_format : '-'}</Typography>
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <Typography className="ord-title">Endereço:</Typography>
                                            <Typography className="ord-value">{data.client_address}</Typography>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Typography className="ord-title">E-mail:</Typography>
                                            <Typography className="ord-value">{ data.client_email ? data.client_email : '-' }</Typography>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Typography className="ord-title">Celular:</Typography>
                                            <Typography className="ord-value">{ data.client_cellphone ? data.client_cellphone : '-' }</Typography>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Typography className="ord-title">Telefone:</Typography>
                                            <Typography className="ord-value">{ data.client_telephone ? data.client_telephone : '-' }</Typography>
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <Typography className="ord-title">Observações:</Typography>
                                            <Typography className="ord-value">{ data.description ? data.description : '-' }</Typography>
                                        </Col>
                                    </Row>
                                </CardAdminComponent>
                            </Col>
                            <Col md={24} xs={24}>
                                <CardAdminComponent title="Ordem de Serviço" subtitle={'Prestadores para visita técnica'}>
                                        <List
                                            size="small"
                                            locale={{emptyText: 'Nenhum prestador encontrado'}}
                                            dataSource={data.tecnica}
                                            renderItem={(item:any, index) => (
                                                <List.Item key={item.id}>
                                                    <List.Item.Meta title={item.name} description={item.user_type_name} />
                                                    <Switch defaultChecked={Boolean(Number(item.was_present))} onChange={(v) => changePresent(v, item.budget_user_id)} unCheckedChildren="Não presente" checkedChildren="Presente" style={{marginRight: 10}} />
                                                </List.Item>
                                            )}
                                        />
                                </CardAdminComponent>
                            </Col>
                            <Col md={24} xs={24}>
                                <CardAdminComponent title="Ordem de Serviço" subtitle={'Informações adicionais'}>
                                    <Form layout="vertical" form={formQuestion} disabled={true}>
                                        <List
                                            dataSource={question}
                                            loading={loadQuestion}
                                            locale={{emptyText: 'Nenhuma informação adicional encontrada'}}
                                            renderItem={(item:any, index) => (
                                                <List.Item key={item.id}>
                                                    <Form.Item name={`question#${item.id}`} label={item.question}  style={{width: '100%', margin: 0}}>
                                                    { item.type_question === 'open' ? <Input.TextArea rows={4} readOnly style={{width: '100%'}} /> : 
                                                        item.type_question === 'close' ? <Radio.Group> { item.answers.map((v:any, i:any) => <Radio value={v} key={i}>{v}</Radio> ) } </Radio.Group> :
                                                        item.type_question === 'multiple' ? <Checkbox.Group> { item.answers.map((v:any, i:any) => <Checkbox value={v} key={i}>{v}</Checkbox> ) } </Checkbox.Group> : null }
                                                    </Form.Item>
                                                </List.Item>
                                            )}
                                        />
                                    </Form>
                                </CardAdminComponent>
                            </Col>
                            { data.services.map((v:any, i:number) => v.has_technical_visit === '1' ? (
                                <Col md={24} xs={24}>
                                    <CardAdminComponent title={v.name} subtitle={'Visita técnica'}>
                                        <Form layout="vertical" initialValues={v.info} onFinish={(values) => onSend(values, v.id)}>
                                            <List
                                                dataSource={v.question}
                                                locale={{emptyText: 'Nenhuma informação adicional encontrada'}}
                                                renderItem={(item:any, index) => (
                                                    <List.Item key={item.id}>
                                                        <Form.Item name={`question#${item.id}`} label={item.question}  style={{width: '100%', margin: 0}}>
                                                        { item.type_question === 'open' ? <Input.TextArea rows={4} style={{width: '100%'}} /> : 
                                                            item.type_question === 'close' ? <Radio.Group> { item.answers.map((v:any, i:any) => <Radio value={v} key={i}>{v}</Radio> ) } </Radio.Group> :
                                                            item.type_question === 'multiple' ? <Checkbox.Group> { item.answers.map((v:any, i:any) => <Checkbox value={v} key={i}>{v}</Checkbox> ) } </Checkbox.Group> : null }
                                                        </Form.Item>
                                                    </List.Item>
                                                )}
                                            />
                                            <Button htmlType="submit" shape="round" type="default" className="btn-default" size="small" style={{float: 'right'}} loading={loadSend}>Salvar</Button>
                                        </Form>
                                    </CardAdminComponent>
                                </Col>
                            ) : null )}
                            <Col span={24}>
                                <Button shape="round" onClick={onFinish} className="btn-primary" type="primary" style={{float: 'right', marginLeft: 6}} loading={loadSend}>Finalizar visita técnica</Button>
                                <Button shape="round" className="btn-default" type="default" style={{float: 'right'}} onClick={() => navigate('..')}>Cancelar</Button>
                            </Col>
                        </Row>
                    }
                </Col>
            </Row>
        </PageDefaultComponent>
    )

}

export default OrdensDeServicoView;
// react libraries
import { useRef, useState } from "react";
import { Affix, Button, Carousel, Col, Drawer, FloatButton, Form, Input, Layout, Row, Typography } from "antd";
import { Link } from "react-router-dom";

// components
import MVVComponent from "../components/MVVComponent";
import ServiceComponent from "../components/ServiceComponent";
import CardComponent from "../components/CardComponent";

// icons
import { IoChevronUp, IoLogoFacebook, IoLogoInstagram, IoMailOutline, IoMenu, IoPhonePortraitOutline } from 'react-icons/io5';
import { FiEye, FiMapPin, FiTarget, FiUserCheck } from 'react-icons/fi';

// css
import './index.css';

const LandingPage = () => {

    // states
    const [ menu, setMenu ] = useState<boolean>(false)

    // references
    const refHome = useRef<any>()
    const refSobreNos = useRef<any>()
    const refServicos = useRef<any>()
    const refEquipeNGL = useRef<any>()
    const refContato = useRef<any>()

    // ref function
    const toRef = (ref: any) => {
        ref?.current?.scrollIntoView()
    }

    // logo
    const logo = '/assets/img/logo.png';
    const logoAlt = '/assets/img/logoBranco.png';

    // slide
    const slide1 = '/assets/img/slide/slide-1.png';
    const slide2 = '/assets/img/slide/slide-2.png';
    const slide3 = '/assets/img/slide/slide-3.png';
    const slide4 = '/assets/img/slide/slide-4.png';

    // img sobre nos
    const about = '/assets/img/about.png';

    // img serviços
    const srv1 = '/assets/img/portfolio/pragas.png';
    const srv2 = '/assets/img/portfolio/tratamento.png';
    const srv3 = '/assets/img/portfolio/efluentes.png';
    const srv4 = '/assets/img/portfolio/qualidade.png';
    const srv5 = '/assets/img/portfolio/sanitizacao.png';

    // menu function
    const onMenu = () => setMenu(!menu)

    return (
        <Layout.Content style={{overflowX: 'hidden'}}>
            <Row className="lp-hd" justify={'space-between'} align={'middle'} ref={refHome}>
                <Col>
                    <Row align={'middle'} gutter={[16,16]}>
                        <Link target="_blank" to="mailto:atendimento@nlgsolutions.com.br">
                            <Col> 
                                <Row align={'middle'} gutter={[4,4]}>
                                    <Col style={{height: 16.8}}><IoMailOutline className="lp-hd-icon" /></Col>
                                    <Col style={{height: 24.8}}><Typography className="lp-hd-text">atendimento@nlgsolutions.com.br</Typography></Col>
                                </Row>
                            </Col>
                        </Link>
                        <Link target="_blank" to="https://wa.me/5514991375061">
                            <Col> 
                                <Row align={'middle'} gutter={[4,4]}>
                                    <Col style={{height: 16.8}}><IoPhonePortraitOutline className="lp-hd-icon" /></Col>
                                    <Col style={{height: 24.8}}><Typography className="lp-hd-text">(14) 99137-5061</Typography></Col>
                                </Row>
                            </Col>
                        </Link>
                    </Row>
                </Col>
                <Col>
                    <Row align={'middle'} gutter={[8,8]}>
                        <Col> <Link target="_blank" to="https://www.facebook.com/nlgsolutions"><IoLogoFacebook className="lp-hd-link" /> </Link> </Col>
                        <Col> <Link target="_blank" to="https://www.instagram.com/nlgsolutions"><IoLogoInstagram className="lp-hd-link" /> </Link> </Col>
                    </Row>
                </Col>
            </Row>
            <Affix offsetTop={0} className='lp-nav-affix'>
                <Row className='lp-nav' justify={'space-between'} align={'middle'}>
                    <Col> <img onClick={() => toRef(refHome)} alt="NLG Solutions" src={logo} className="lp-nav-logo" /> </Col>
                    <Col>
                        <Row gutter={[8,8]} align={'middle'}>
                            <Col> <Button className="lp-nav-btn-menu" shape="circle" onClick={onMenu}><IoMenu /></Button> </Col>
                            <Col className="lp-nav-col"> <Button className="lp-nav-btn" onClick={() => toRef(refHome)}>Home</Button> </Col>
                            <Col className="lp-nav-col"> <Button className="lp-nav-btn" onClick={() => toRef(refSobreNos)}>Sobre nós</Button> </Col>
                            <Col className="lp-nav-col"> <Button className="lp-nav-btn" onClick={() => toRef(refServicos)}>Serviços</Button> </Col>
                            <Col className="lp-nav-col"> <Button className="lp-nav-btn" onClick={() => toRef(refEquipeNGL)}>Equipe NLG</Button> </Col>
                            <Col className="lp-nav-col"> <Button className="lp-nav-btn" onClick={() => toRef(refContato)}>Contato</Button> </Col>
                            <Col className="lp-nav-col"> <Link to="sistema"><Button className="lp-nav-btn lp-nav-btn-primary">Área do Cliente</Button></Link> </Col>
                        </Row>
                    </Col>
                </Row>
            </Affix>
            <Row className="lp-carousel">
                <Col span={24}>
                    <Carousel arrows fade>
                        <div> <img alt="slide-1" src={slide1} width={'100%'} /> </div>
                        <div> <img alt="slide-2" src={slide2} width={'100%'} /> </div>
                        <div> <img alt="slide-3" src={slide3} width={'100%'} /> </div>
                        <div> <img alt="slide-4" src={slide4} width={'100%'} /> </div>
                    </Carousel>
                </Col>
            </Row>
            <Row className="lp-mvv" gutter={[32,32]}>
                <Col md={8} xs={24}>
                    <MVVComponent
                        icon={<FiTarget className="mvv-icon" />}
                        title="Missão"
                        text="Disponibilizar soluções, realizar serviços com qualidade, respeitando ao meio ambiente e proporcionar qualidade de vida à sociedade."
                    />
                </Col>
                <Col md={8} xs={24}>
                    <MVVComponent
                        icon={<FiEye className="mvv-icon" />}
                        title="Visão"
                        text="Ser referência no segmento de prestação de serviços à âmbito nacional, alcançar alta rentabilidade e contar com colaboradores excepcionais."
                    />
                </Col>
                <Col md={8} xs={24}>
                    <MVVComponent
                        icon={<FiUserCheck className="mvv-icon" />}
                        title="Valores"
                        text="Honestidade; Comprometimento; Ética; Determinação; Competência; Respeito ao Meio Ambiente; Cordialidade entre funcionários, clientes e fornecedores."
                    />
                </Col>
            </Row>
            <Row className="lp-sobrenos" gutter={[32,16]} ref={refSobreNos}>
                <Col span={24}>
                    <Typography className="lp-rw-title">Sobre nós</Typography>
                </Col>
                <Col span={24}>
                    <Typography className="lp-rw-text">Somos uma empresa comprometida com nossos clientes e colaboradores.</Typography>
                </Col>
                <Col xs={24} md={12}>
                    <Typography className="lp-sobrenos-title">Quem é a NLG Solutions</Typography>
                    <Typography className="lp-sobrenos-text">A empresa foi fundada em 2016, inicialmente atuando no segmento de representação comercial para análises laboratoriais químicas e microbiológicas. Em 2018, novas atividades foram incorporadas, tais como prestação de serviços técnicos especializados, consultorias, treinamentos, auditorias e calibração de equipamentos.</Typography>
                    <Typography className="lp-sobrenos-text">Atualmente, oferecemos soluções para diversas áreas, incluindo indústrias, comércios, condomínios, hospitais, clubes, hotéis, escolas, restaurantes, rodovias, prefeituras, residências, entre outras</Typography>
                </Col>
                <Col xs={24} md={12}><img alt="sobrenos" src={about} className="lp-sobrenos-img" /></Col>
            </Row>
            <Row className="lp-sobrenos" gutter={[32,16]} ref={refServicos}>
                <Col span={24}>
                    <Typography className="lp-rw-title">Nossos serviços</Typography>
                </Col>
                <Col span={24}>
                    <Typography className="lp-rw-text">Veja aqui todos os tipos de serviços que prestamos.</Typography>
                </Col>
                <Col md={8} xs={24}>
                    <ServiceComponent img={srv1} title="Controle e imunização de pragas urbanas">
                        <Typography className="srv-text">A NLG SOLUTIONS oferece soluções em prestação de serviços para controle e imunização de pragas urbanas, também conhecida por dedetização, e possui um sistema de gestão estruturado de forma que assegure a padronização e qualidade dos processos.</Typography>
                        <Typography className="srv-subtitle">Combatemos as pragas</Typography>
                        <Typography className="srv-text">Baratas, formigas, pombos, insetos, cupins, traças, ratos, escorpiões, vespas, aranhas, pulgas, carrapatos e muito mais!</Typography>
                    </ServiceComponent>
                </Col>
                <Col md={8} xs={24}>
                    <ServiceComponent img={srv2} title="Tratamento e análise em água de abastecimento">
                        <Typography className="srv-text">A NLG SOLUTIONS oferece soluções em prestação de serviços para tratamento de água para consumo, podendo ser subterrânea ou superficial contando com especialistas e técnicos treinados para efetuar o tratamento ou prestar consultorias no local.</Typography>
                        <Typography className="srv-text">Realizamos análises de controle de qualidade que são possíveis no local, os demais ensaios são encaminhados á laboratórios parceiros e acreditados pelo INMETRO na ISO/IEC 17025:2017.</Typography>
                    </ServiceComponent>
                </Col>
                <Col md={8} xs={24}>
                    <ServiceComponent img={srv3} title="Tratamento e análise em efluentes (Esgoto)">
                        <Typography className="srv-text">A NLG SOLUTIONS oferece soluções em prestação de serviços para tratamento de efluentes seja industrial ou municipal contando com especialistas e técnicos treinados para efetuar o tratamento ou prestar consultoria no local.</Typography>
                        <Typography className="srv-text">Realizamos análises de controle de qualidade que são possíveis no local, os demais ensaios são encaminhados á laboratórios parceiros e acreditados pelo INMETRO na ISO/IEC 17025:2017.</Typography>
                    </ServiceComponent>
                </Col>
                <Col md={8} xs={24}>
                    <ServiceComponent img={srv4} title="Gestão de Controle de Qualidade">
                        <Typography className="srv-text">A NLG SOLUTIONS possui profissionais à nível de especialista para realização de Auditorias, Treinamentos e Consultorias na área de gestão e controle da qualidade e requisitos técnicos baseadas nas normas a seguir:</Typography>
                        <ul>
                            <li>ISO IEC 17025:2017</li>
                            <li>ISO 9001:2015</li>
                            <li>ISO 14001:2015</li>
                            <li>ISO 45001:2018</li>
                            <li>OHSAS 18001:2007</li>
                        </ul>
                        <Typography className="srv-text">Trabalhamos com a realização do controle de qualidade da sua empresa, desde a confecção e controle de documentos até controle de qualidade de seu produto ou serviço.</Typography>
                    </ServiceComponent>
                </Col>
                <Col md={8} xs={24}>
                    <ServiceComponent img={srv5} title="Sanitização de Ambientes">
                        <Typography className="srv-text">A sanitização é uma técnica desenvolvida para proteger a saúde e qualidade de vida das pessoas, eliminando bactérias, fungos e ácaros, e evitando a contaminação por diversas doenças e processos alérgicos respiratórios. Além disso, a sanitização também ajuda na conservação de superfícies de móveis, paredes, objetos e tecidos, evitando manchas e reduzindo custos com manutenção. A técnica ainda cria uma película ativa protetora que impede a proliferação de bactérias, ácaros e fungos por até seis meses, facilitando a manutenção e a limpeza das superfícies. A NLG SOLUTIONS utiliza produtos aprovados pelo Ministério da Saúde (ANVISA) e Ministério da Agricultura, além de fornecer certificado de aplicação.</Typography>
                    </ServiceComponent>
                </Col>
            </Row>
            <Row className="lp-sobrenos" gutter={[16,32]} ref={refContato}>
                <Col span={24}>
                    <Typography className="lp-rw-title">Contato</Typography>
                </Col>
                <Col md={10} xs={24}>
                    <CardComponent>
                        <Row gutter={[8,8]} className="lp-cnt-row">
                            <Col flex={'4em'}>
                                <div className="lp-cnt-icon-div"><FiMapPin className="lp-cnt-icon" /></div>
                            </Col>
                            <Col flex={'auto'}>
                                <Typography className="lp-cnt-title">Endereço:</Typography>
                                <Typography className="lp-cnt-text">Rua Sassaichi Masaki, 472 | Centro | Promissão | São Paulo</Typography>
                            </Col>
                        </Row>
                        <Row gutter={[8,8]} className="lp-cnt-row">
                            <Col flex={'4em'}>
                                <div className="lp-cnt-icon-div"><IoMailOutline className="lp-cnt-icon" /></div>
                            </Col>
                            <Col flex={'auto'}>
                                <Typography className="lp-cnt-title">Email:</Typography>
                                <Typography className="lp-cnt-text">atendimento@nlgsolutions.com.br</Typography>
                            </Col>
                        </Row>
                        <Row gutter={[8,8]} className="lp-cnt-row">
                            <Col flex={'4em'}>
                                <div className="lp-cnt-icon-div"><IoPhonePortraitOutline className="lp-cnt-icon" /></div>
                            </Col>
                            <Col flex={'auto'}>
                                <Typography className="lp-cnt-title">Telefone:</Typography>
                                <Typography className="lp-cnt-text">(14) 99137-5061</Typography>
                            </Col>
                        </Row>
                        <Row gutter={[8,8]} className="lp-cnt-row">
                            <Col flex={'auto'}>
                                <iframe title="Mapa" style={{border: 'none'}} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d927.7782740563232!2d-49.85804863031141!3d-21.542432648764912!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94be05014c0d2ff7%3A0xd0f35d379bc2a414!2sNLG%20Solutions!5e0!3m2!1spt-BR!2sbr!4v1710370065580!5m2!1spt-BR!2sbr" width="100%" height="300" loading="lazy"></iframe>
                            </Col>
                        </Row>
                    </CardComponent>
                </Col>
                <Col md={14} xs={24}>
                    <CardComponent>
                        <Form layout="vertical">
                            <Row gutter={[16,0]}>
                                <Col md={12} xs={24}>
                                    <Form.Item name="" label="Seu nome">
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col md={12} xs={24}>
                                    <Form.Item name="" label="Seu e-mail">
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col md={24} xs={24}>
                                    <Form.Item name="" label="Assunto">
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col md={24} xs={24}>
                                    <Form.Item name="" label="Mensagem">
                                        <Input.TextArea rows={10} style={{ resize: 'none' }} />
                                    </Form.Item>
                                </Col>
                                <Col md={24} xs={24}>
                                    <center><Button size="large" className="btn-primary">Enviar mensagem</Button></center>
                                </Col>
                            </Row>
                        </Form>
                    </CardComponent>
                </Col>
            </Row>
            <Row className="lp-footer" gutter={[8,8]} justify={'center'} align={'middle'}>
                <Col> <img src={logoAlt} alt="NGL Solutions" className="lp-footer-img" /> </Col>
                <Col>
                    <Row gutter={[16,16]}>
                        <Col> <Link target="_blank" to="https://www.facebook.com/nlgsolutions"><div className="lp-footer-button"><IoLogoFacebook className="lp-footer-icon" /></div></Link> </Col>
                        <Col> <Link target="_blank" to="https://www.instagram.com/nlgsolutions"><div className="lp-footer-button"><IoLogoInstagram className="lp-footer-icon" /></div></Link> </Col>
                    </Row>
                </Col>
                <Col> <Typography className="lp-footer-text"> © Copyright <b>NLG Solutions</b>. Todos os direitos reservados <br /> Desenvolvido por <Link target="_blank" to="https://adsolucoestecnologia.com.br">AD Soluções em Tecnologia</Link> </Typography> </Col>
            </Row>
            <Drawer open={menu} onClose={onMenu} width={'20em'}>
                <Row gutter={[8,8]} align={'middle'}>
                    <Col span={24}> <Button block className="lp-nav-btn" onClick={() => {toRef(refHome); onMenu()}}>Home</Button> </Col>
                    <Col span={24}> <Button block className="lp-nav-btn" onClick={() => {toRef(refSobreNos); onMenu()}}>Sobre nós</Button> </Col>
                    <Col span={24}> <Button block className="lp-nav-btn" onClick={() => {toRef(refServicos); onMenu()}}>Serviços</Button> </Col>
                    <Col span={24}> <Button block className="lp-nav-btn" onClick={() => {toRef(refEquipeNGL); onMenu()}}>Equipe NLG</Button> </Col>
                    <Col span={24}> <Button block className="lp-nav-btn" onClick={() => {toRef(refContato); onMenu()}}>Contato</Button> </Col>
                    <Col span={24}> <Link to="sistema"><Button block className="lp-nav-btn lp-nav-btn-primary">Área do Cliente</Button></Link> </Col>
                </Row>
            </Drawer>
            <FloatButton.BackTop visibilityHeight={10} icon={<IoChevronUp />} className="lp-backtop" />
        </Layout.Content>
    )

}

export default LandingPage;
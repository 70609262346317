// css
import { Typography } from 'antd';
import './index.css';

// interface
interface ServiceInterface {
    img: any,
    title: string,
    children: any
}

const ServiceComponent = ( { img, title, children } : ServiceInterface ) => {

    return (
        <div className='srv'>
            <center><img src={img} alt={title} className='srv-img' /></center>
            <Typography className='srv-title'>{title}</Typography>
            {children}
        </div>
    )

}

export default ServiceComponent;
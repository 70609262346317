// react libraries
import { Typography } from 'antd';
import { useNavigate } from 'react-router-dom';

// css
import './index.css';
import { verifyConfig } from '../../services';

// interface
interface MenuitemInterface {
    permission: Array<number>,
    route?: string,
    url?: string,
    icon?: any,
    name: string,
    menu?: boolean,
}

const MenuitemComponent = ( { permission, route, url, icon, name } : MenuitemInterface ) => {

    // responsible for the route
    const navigate = useNavigate()

    if (verifyConfig(permission)) {
        return (
            <div className={`mnu-content ${ url === route ? 'active' : '' }`} onClick={() => navigate(`/sistema/${route}`)}>
                <div className='mnu-icon'>{icon}</div>
                <Typography className='mnu-text'>{name}</Typography>
            </div>
        )
    }
    return null

}

export default MenuitemComponent;
// react libraries
import { Col, Row, Typography } from "antd";
import { useState } from "react";

// services
import { PageDefaultProps } from "../../../services";

// components
import TableComponent from "../../../components/TableComponent";
import CardAdminComponent from "../../../components/CardAdminComponent";
import PageDefaultComponent from "../../../components/PageDefaultComponent";

// buttons components
import { TableNewButton, TableTrEditButton, TableTrEyeButton, TableTrTrashButton } from "../../../components/TableComponent/buttons";

const PragasAlvo = ( { type, path, permission } : PageDefaultProps ) => {

    // states
    const [ action, setAction ] = useState(false);

    // table columns
    const column = [
        { title: 'Código', dataIndex: 'id', table: 'target_pest.id', width: '100px', sorter: true, align: 'center', render: null },
        { title: 'Nome', dataIndex: 'name', table: 'target_pest.name', width: 'auto', minWidth: '240px', sorter: true, align: 'left', render: null },
        { title: 'Ações', dataIndex: null, width: '120px', sorter: false, align: 'center', render: (item: any) => (
            <Row justify={'center'} style={{width: '100%'}}>
                <TableTrEyeButton type={type} permission={permission} item={item} />
                <TableTrEditButton type={type} permission={permission} item={item} />
                <TableTrTrashButton type={type} permission={permission} item={item} action={() => setAction(!action)} path={path} />
            </Row>
        ) },
    ]

    return (
        <PageDefaultComponent title="Gerenciamento de Pragas Alvo" items={[
            { title: type === 'list' ? 'Lista' : 'Lixeira' },
        ]}>
            <Row gutter={[20,20]}>
                <Col md={24} xs={24}>
                    <CardAdminComponent title="Pragas Alvo" subtitle={type === 'list' ? 'Lista' : 'Lixeira'} options={
                        <Row justify={'end'} gutter={[8,8]}>
                            <TableNewButton type={type} permission={permission} />
                        </Row>
                    }>
                        <TableComponent 
                            column={column}
                            path={path}
                            type={type}
                            action={action}
                        />
                    </CardAdminComponent>
                </Col>
            </Row>
        </PageDefaultComponent>
    )

}

export default PragasAlvo;
// react libraries
import { Avatar, Button, Col, Form, Input, Modal, Row, Tag, Typography, Upload, message } from "antd";
import { useEffect, useState } from "react";
import ImgCrop from "antd-img-crop";

// services
import { ANTINPUT, CLN, POST_API, POST_CATCH, getToken, getUPLOADAPI, verifyConfig } from "../../../services";

// components
import LoadItemComponent from "../../../components/LoadItemComponent";
import CardAdminComponent from "../../../components/CardAdminComponent";
import PageDefaultComponent from "../../../components/PageDefaultComponent";

// icons
import { IoCameraOutline } from "react-icons/io5";

// css
import './index.css';
import ReactInputMask from "react-input-mask";

const Perfil = ( ) => {

    // states
    const [ load, setLoad ] = useState(true);
    const [ doc, setDoc ] = useState(false);
    const [ user, setUser ] = useState<any>(null);
    const [ loadButton, setLoadButton ] = useState<boolean>(false);
    const [ loadPassButton, setLoadPassButton ] = useState<boolean>(false);
    
    // form
    const [ form ] = Form.useForm()
    const [ formPass ] = Form.useForm()

    // edit photo
    const onChangePic = (value:any) => {
        if (value.file.response?.url) {
            POST_API(`/${ verifyConfig([CLN]) ? 'client' : 'user' }/self-save.php`, { token: getToken(), master: JSON.stringify({photo: value.file.response?.url}) }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    view()
                } else { Modal.warning({ title: 'Algo deu errado', content: res.msg }) }
            }).catch(POST_CATCH)
        }
    }

    // edit password
    const onSendPass = (values:any) => {
        setLoadPassButton(true)
        POST_API(`/${ verifyConfig([CLN]) ? 'client' : 'user' }/self-pass.php`, { token: getToken(), master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                Modal.success({ title: 'Sucesso', content: res.msg})
                formPass.resetFields()
            } else { Modal.warning({ title: 'Algo deu errado', content: res.msg }) }
        }).catch(POST_CATCH).finally(() => setLoadPassButton(false))
    }

    // edit data
    const onSend = (values:any) => {

        setLoadButton(true)
        POST_API(`/${ verifyConfig([CLN]) ? 'client' : 'user' }/self-save.php`, { token: getToken(), master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                view()
            } else { Modal.warning({ title: 'Algo deu errado', content: res.msg }) }
        }).catch(POST_CATCH).finally(() => setLoadButton(false))

    }

    // view
    const view = () => {
        setUser(null)
        POST_API(`/${ verifyConfig([CLN]) ? 'client' : 'user' }/search.php`, { token: getToken(), type: 'self' }).then(rs => rs.json()).then(res => {
            if (res.return) {
                form.setFieldsValue(res.data)
                setUser(res.data)
            }
        }).catch(POST_CATCH)
    }

    const onValidDoc = ( event: any ): any => {
        var text = String(event.target.value).replaceAll('_', '')
        if (event.which !== 9) {
            setDoc(text.length > 13)
        }
    }

    // search cep
    const onCEP = async () => {
        await fetch('https://viacep.com.br/ws/' + String(form.getFieldValue('cep')).replaceAll('-', '') + '/json/').then(rs => rs.json()).then(res => {
            if (res?.erro) {
                message.warning('CEP inválido')
            } else {
                form.setFieldValue('street', res.logradouro)
                form.setFieldValue('district', res.bairro)
                form.setFieldValue('city', res.localidade)
                form.setFieldValue('uf', res.uf)
            }
        }).catch(POST_CATCH)
    }

    useEffect(() => view(), [])

    return (
        <PageDefaultComponent title="Perfil" items={[
            { title: 'Meu Perfil' },
        ]}>
            <Row gutter={[20,20]}>
                <Col md={24} xs={24}>
                    { user === null ? <LoadItemComponent /> : (
                        <Row gutter={[16,16]}>
                            <Col xs={24} md={7} className="mp-col">
                                <CardAdminComponent>
                                    <Row justify={'center'} align={'middle'} style={{flexDirection:'column'}}>
                                        <Col><Avatar className="mp-avatar" src={user.photo} /></Col>
                                        <ImgCrop>
                                            <Upload className="btn-upload-div" maxCount={1} showUploadList={false} accept="image/jpg,image/jpeg,image/png" action={getUPLOADAPI} onChange={onChangePic}>
                                                <Button shape="circle" type="default" className="btn-upload btn-default"><IoCameraOutline /></Button>
                                            </Upload>
                                        </ImgCrop>
                                        <Col><Typography className="mp-name">{user.name}</Typography></Col>
                                        <Col><Tag color={user.user_type_color}>{user.user_type_name}</Tag></Col>
                                    </Row>
                                </CardAdminComponent>
                                <div style={{marginTop: '1.2em'}}></div>
                                <CardAdminComponent title="Minha senha" subtitle="Alterar">
                                    <Form form={formPass} layout="vertical" onFinish={onSendPass}>
                                        <Form.Item name="password_old" label="Senha Atual" rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                            <Input.Password placeholder="Senha Atual" />
                                        </Form.Item>
                                        <Form.Item name="password_new" label="Senha Nova" rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                            <Input.Password placeholder="Senha Nova" />
                                        </Form.Item>
                                        <Button shape="round" htmlType="submit" type="primary" className="btn-primary" style={{float: 'right', marginLeft: 6}} loading={loadPassButton}>Alterar</Button>
                                    </Form>
                                </CardAdminComponent>
                            </Col>
                            <Col xs={24} md={17}>
                                <CardAdminComponent title="Meus dados" subtitle="Alterar">
                                    <Form layout="vertical" form={form} onFinish={onSend}>
                                        <Row gutter={[8,8]}>
                                            { !verifyConfig([CLN]) ?
                                                <Col xs={24} md={8}>
                                                    <Form.Item name="login" label="Login" rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                                        <Input placeholder="Login" disabled/>
                                                    </Form.Item>
                                                </Col>
                                            : null }
                                            <Col xs={24} md={16}>
                                                <Form.Item name="name" label="Nome" rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                                    <Input placeholder="Nome"/>
                                                </Form.Item>
                                            </Col>
                                            { verifyConfig([CLN]) ?
                                                <>
                                                    <Col xs={24} md={8}>
                                                        <Form.Item name="cpf_cnpj" label="CPF/CNPJ" rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                                            <ReactInputMask onKeyDown={onValidDoc} mask={ doc ? '99.999.999/9999-99' : '999.999.999-99' } placeholder={ doc ? 'CNPJ' : 'CPF' } className={ANTINPUT} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col md={5} xs={24}>
                                                        <Form.Item name="birthday" label={ doc ? 'Data início atividades' : 'Data de nascimento' }>
                                                            <Input placeholder={ doc ? 'Data início atividades' : 'Data de nascimento' } type="date" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={12} md={4}>
                                                        <Form.Item name="rg_ie" label="RG/IE" rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                                            <Input placeholder="RG/IE"/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={12} md={4}>
                                                        <Form.Item name="cep" label="CEP" rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                                            <ReactInputMask mask='99999-999' placeholder='CEP' className={ANTINPUT} onBlur={onCEP} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} md={11}>
                                                        <Form.Item name="street" label="Endereço" rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                                            <Input placeholder="Endereço"/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} md={4}>
                                                        <Form.Item name="numb" label="Número">
                                                            <Input placeholder="Número"/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} md={10}>
                                                        <Form.Item name="district" label="Bairro" rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                                            <Input placeholder="Bairro"/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={20} md={8}>
                                                        <Form.Item name="city" label="Cidade" rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                                            <Input placeholder="Cidade"/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={4} md={2}>
                                                        <Form.Item name="uf" label="UF" rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                                            <Input placeholder="UF"/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col md={12} xs={24}>
                                                        <Form.Item name="email" label="E-mail" rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                                            <Input placeholder='E-mail' type="email" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col md={6} xs={12}>
                                                        <Form.Item name="telephone" label="Telefone">
                                                            <ReactInputMask mask='(99) 9999-9999' placeholder='Telefone' className={ANTINPUT} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col md={6} xs={12}>
                                                        <Form.Item name="cellphone" label="Celular">
                                                            <ReactInputMask mask='(99) 99999-9999' placeholder='Telefone' className={ANTINPUT} />
                                                        </Form.Item>
                                                    </Col>
                                                </>
                                            : null }
                                            <Col span={24}>
                                                <Button shape="round" htmlType="submit" className="btn-primary" type="primary" style={{float: 'right', marginLeft: 6}} loading={loadButton}>Salvar</Button>
                                                <Button shape="round" onClick={view} className="btn-default" type="default" style={{float: 'right', marginLeft: 6}} loading={loadButton}>Cancelar</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardAdminComponent>
                            </Col>
                        </Row>
                    ) }
                </Col>
            </Row>
        </PageDefaultComponent>
    )

}

export default Perfil;
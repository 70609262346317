// react libraries
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Form, Input, InputNumber, Modal, Row, message } from "antd";

// services
import { POST_API, POST_CATCH, PageDefaultProps, getToken } from "../../../services";

// components
import LoadItemComponent from "../../../components/LoadItemComponent";
import CardAdminComponent from "../../../components/CardAdminComponent";
import PageDefaultComponent from "../../../components/PageDefaultComponent";

// buttons components
import { TableReturnButton } from "../../../components/TableComponent/buttons";
import SelectSearchComponent from "../../../components/SelectSearchComponent";

const ContasAPagarForm = ( { type, path, permission } : PageDefaultProps ) => {

    // route responsible
    const navigate = useNavigate()
    
    // params 
    const { ID } = useParams()

    // state
    const [ load, setLoad ] = useState(true)
    const [ loadButton, setLoadButton ] = useState(false)
    
    // form
    const [ form ] = Form.useForm()
    const [ provider, setProvider ] = useState<any>(null)

    // valid new or edit
    useEffect(() => {
        if (type === 'add') { setLoad(false) } else {
            setLoad(true)
            POST_API(`/${path}/search.php`, { token: getToken(), filter: JSON.stringify({ id: ID }), type }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    form.setFieldsValue(res.data)
                    setProvider({id: res.data.provider_id})
                } else { Modal.warning({ title: 'Algo deu errado', content: res.msg }) }
            }).catch(POST_CATCH).finally( () => setLoad(false))
        }
    }, [type, path, form, ID]);

    // function save
    const onSend = (values: any) => {
        setLoadButton(true)
        values.ID = ID
        POST_API(`/${path}/save.php`, { token: getToken(), master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success(res.msg)
                navigate('..')
            } else { Modal.warning({ title: 'Algo deu errado', content: res.msg }) }
        }).catch(POST_CATCH).finally( () => setLoadButton(false) )
    }

    return (
        <PageDefaultComponent title="Gerenciamento de Contas a Pagar" items={[
            { title: type === 'add' ? 'Novo registro' : type === 'view' ? 'Visualizar registro' : 'Editar registro' },
        ]}>
            <Row gutter={[20,20]}>
                <Col md={24} xs={24}>
                    { load ? <LoadItemComponent /> :
                        <CardAdminComponent title="Contas a Pagar" subtitle={type === 'add' ? 'Novo registro' : type === 'view' ? 'Visualizar registro' : 'Editar registro'} options={
                            <Row justify={'end'} gutter={[8,8]}>
                                <TableReturnButton type={type} permission={permission} />
                            </Row>
                        }>
                            <Form disabled={type === 'view'} layout="vertical" onFinish={onSend} form={form}>
                                <Row gutter={[8,0]}>
                                    <Col xs={24} md={24}>
                                        <Form.Item name="provider_id" label='Fornecedor' rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                            <SelectSearchComponent disabled={type === 'view'} placeholder="Fornecedor" effect={provider} value={form.getFieldValue('provider_id')} url="/provider/select.php" change={(v:any) => form.setFieldValue('provider_id', v)}/>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={4}>
                                        <Form.Item name="due_date" label='Data de Vencimento' rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                            <Input placeholder='Data de Vencimento' type="date"/>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={5}>
                                        <Form.Item name="price" label='Valor' rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                            <InputNumber style={{width: '100%'}} addonBefore="R$" placeholder='Valor'/>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={5}>
                                        <Form.Item name="discount" label='Desconto'>
                                            <InputNumber style={{width: '100%'}} addonBefore="R$" placeholder='Desconto'/>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={5}>
                                        <Form.Item name="fine" label='Multa'>
                                            <InputNumber style={{width: '100%'}} addonBefore="R$" placeholder='Multa'/>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={5}>
                                        <Form.Item name="paid" label='Pago'>
                                            <InputNumber style={{width: '100%'}} addonBefore="R$" placeholder='Pago'/>
                                        </Form.Item>
                                    </Col>
                                    { type === 'view' ? null :
                                        <Col span={24}>
                                            <Button shape="round" htmlType="submit" className="btn-primary" type="primary" style={{float: 'right', marginLeft: 6}} loading={loadButton}>Salvar</Button>
                                            <Button shape="round" className="btn-default" type="default" style={{float: 'right'}} onClick={() => navigate('..')}>Cancelar</Button>
                                        </Col>
                                    }
                                </Row>
                            </Form>
                        </CardAdminComponent>
                    }
                </Col>
            </Row>
        </PageDefaultComponent>
    )

}

export default ContasAPagarForm;
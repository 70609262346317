// react libraries
import { Button, Col, Form, Input, Modal, Row, Tag } from "antd";
import { useState } from "react";

// services
import { ADM, DEV, GRN, PageDefaultProps, verifyConfig } from "../../../services";

// components
import TableComponent from "../../../components/TableComponent";
import CardAdminComponent from "../../../components/CardAdminComponent";
import PageDefaultComponent from "../../../components/PageDefaultComponent";

// buttons components
import { TableNewButton, TableTrEditButton, TableTrEyeButton, TableTrTrashButton } from "../../../components/TableComponent/buttons";

const Clientes = ( { type, path, permission } : PageDefaultProps ) => {

    // states
    const [ action, setAction ] = useState(false);
    const [ open, setOpen ] = useState(false);

    // table columns
    const column = [
        { title: 'Código', dataIndex: 'id', table: 'client.id', width: '100px', sorter: true, align: 'center', render: null },
        { title: 'Nome / Razão Social', dataIndex: 'name', table: 'client.name', width: 'auto', minWidth: '240px', sorter: true, align: 'left', render: null },
        { title: 'Telefone', dataIndex: 'telephone', table: 'client.telephone', width: '140px',  sorter: true, align: 'center', render: null },
        { title: 'Celular', dataIndex: 'cellphone', table: 'client.cellphone', width: '140px',  sorter: true, align: 'center', render: null },
        { title: 'Criado por', dataIndex: 'created_by_name', table: 'created.name', width: '140px',  sorter: true, align: 'center', render: null, hide: !verifyConfig([ADM,DEV,GRN]) },
        { title: 'Ações', dataIndex: null, width: '120px', sorter: false, align: 'center', render: (item: any) => (
            <Row justify={'center'} style={{width: '100%'}}>
                <TableTrEyeButton type={type} permission={permission} item={item} />
                <TableTrEditButton type={type} permission={permission} item={item} />
                <TableTrTrashButton type={type} permission={permission} item={item} action={() => setAction(!action)} path={path} />
            </Row>
        ) },
    ]

    const [ form ] = Form.useForm()

    const onSend = () => {
        
        const name = form.getFieldValue('name')
        const cellphone = form.getFieldValue('cellphone')
        window.open(`https://api.whatsapp.com/send?phone=55${cellphone}&text=*Ol%C3%A1%20${name},%20aqui%20%C3%A9%20da%20NLG%20Solutions!*%0APara%20agilizar%20o%20seu%20atendimento,%20solicitamos%20que%20voc%C3%AA%20preencha%20a%20ficha%20de%20cadastro%20da%20nossa%20empresa,%20clicando%20no%20link%20abaixo:%0Ahttps://nlgsolutions.com.br/cadastrese`)
        setOpen(false)
        form.resetFields()

    }

    return (
        <PageDefaultComponent title="Gerenciamento de Clientes" items={[
            { title: type === 'list' ? 'Lista' : 'Lixeira' },
        ]}>
            <Row gutter={[20,20]}>
                <Col md={24} xs={24}>
                    <CardAdminComponent title="Clientes" subtitle={type === 'list' ? 'Lista' : 'Lixeira'} options={
                        <Row justify={'end'} gutter={[8,8]}>
                            <Col><Button onClick={() => setOpen(true)} size="small" type='default' className="btn-default" shape="round">Enviar formulário</Button></Col>
                            <TableNewButton type={type} permission={permission} />
                        </Row>
                    }>
                        <TableComponent 
                            column={column}
                            path={path}
                            type={type}
                            action={action}
                        />
                    </CardAdminComponent>
                </Col>
            </Row>
            <Modal open={open} onCancel={() => setOpen(false)} title="Envio de formulário de cadastro" width={350} okText="Enviar" cancelText="Cancelar" onOk={onSend}>
                <Form layout="vertical" form={form}>
                    <Form.Item label="Nome do cliente" name="name">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Celular" name="cellphone">
                        <Input placeholder="14999999999" />
                    </Form.Item>
                </Form>
            </Modal>
        </PageDefaultComponent>
    )

}

export default Clientes;
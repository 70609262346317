// react libraries
import { useEffect, useState } from "react";
import { Avatar, Col, Dropdown, Layout, Modal, Row, Typography } from "antd";
import { Link, Outlet, useNavigate } from "react-router-dom";
import detectUrlChange from "detect-url-change";

// services
import { ADM, CLN, DEV, GRN, OPR, POST_API, REP, delConfig, delToken, getToken, verifyConfig } from "../../services";

// components
import LoadComponent from "../../components/LoadComponent";
import MenuitemComponent from "../../components/MenuItemComponent";

// icons
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { MdOutlinePestControlRodent } from "react-icons/md";
import { TbFileCertificate, TbQuestionMark, TbRulerMeasure } from "react-icons/tb";
import { IoArchiveOutline, IoBugOutline, IoBusinessOutline, IoCalendarOutline, IoCardOutline, IoCashOutline, IoGridOutline, IoIdCardOutline, IoLogOutOutline, IoMenu, IoNewspaperOutline, IoPeopleOutline, IoPersonOutline } from "react-icons/io5";

// css
import './index.css';

const Sistema = () => {

    // responsible route
    const navigate = useNavigate()

    // states
    const [ user, setUser ] = useState<any>(null)
    const [ menu, setMenu ] = useState<boolean>(true)
    const [ url, setUrl ] = useState<string>(window.location.href.split('/')[4]);

    // logo
    const logo = '/assets/img/logo.png';

    // function logout
    const onLogOut = () => {
        Modal.confirm({
            title: 'Sair do sistema?', icon: <ExclamationCircleOutlined />, cancelText: 'Não', okText: 'Sim',
            okButtonProps: { className: 'btn-primary' }, cancelButtonProps: { className: 'btn-default' },
            onOk() {
                delConfig()
                delToken()
                navigate('/login')
            },
            onCancel() {},
        })
    }

    // function change menu
    const onMenu = () => {
        setMenu(!menu)
    }

    useEffect(() => {
        // function that observes change in route to update active menu
        detectUrlChange.on('change', (newUrl) => {
            var params = String(newUrl).split('/')
            setUrl(params[4])
            if (getToken() != null) { } else { 
                navigate('/login')
            }
        });
    }, [])

    useEffect(() => {
        
        if (verifyConfig([OPR])) navigate('/sistema/ordensdeservico')

        if (getToken() != null) {
            POST_API('/user/verify.php', { token: getToken() }).then(rs => rs.json()).then(res => {
                if (!res.return) { navigate('/login') } else {
                    setUser(res.user)
                }
            })
        } else { navigate('/login') }

    }, []);

    if (user === null) return (
        <Layout>
            <Row justify={'center'} align={'middle'} style={{height: '100vh', width: '100vw'}}>
                <Col><LoadComponent title="Carregando dados do usuário" /></Col>
            </Row>
        </Layout>
    )

    return (
        <Layout>
            <Row className="dsh-content">
                <div className="dsh-div-logo"><img src={logo} alt="NLG Solutions" className="dsh-logo"/></div>
                <Col span={24}>
                    <Row className="dsh-navbar" justify="space-between" align="middle">
                        <Col><IoMenu className="dsh-menu" onClick={onMenu} /></Col>
                        <Col>
                            <Dropdown menu={{ items: [
                                { key: 'perfil', label: <Link to="/sistema/meuperfil">Meu Perfil</Link>, icon: <IoIdCardOutline color="var(--color01)" size={18} /> },
                                { key: 'sair', label: 'Sair', icon: <IoLogOutOutline color="#FFF" size={18} />, style: { backgroundColor: 'var(--color01)', color: '#FFF' }, onClick: onLogOut }
                            ] }} arrow>
                                <Row gutter={[4,4]} className="dsh-head-user">
                                    <Col><Avatar className="dsh-head-avatar" src={user.photo} /></Col>
                                    <Col className="dsh-head-text">
                                        <Typography className="dsh-head-typeuser">{user.user_type_name}</Typography>
                                        <Typography className="dsh-head-nameuser">{user.name}</Typography>
                                    </Col>
                                </Row>
                            </Dropdown>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Row className="dsh-page">
                        <Col flex={'20em'} className={`dsh-page-sidebar ${ menu ? 'active' : '' }`}>
                            <MenuitemComponent url={url} route="dashboard" name="Dashboard" icon={<IoGridOutline />} permission={[DEV,ADM,GRN]} />
                            <MenuitemComponent url={url} route="fornecedores" name="Fornecedores" icon={<IoBusinessOutline />} permission={[DEV,ADM]} />
                            <MenuitemComponent url={url} route="produtos" name="Produtos" icon={<IoArchiveOutline />} permission={[DEV,ADM,GRN]} />
                            <MenuitemComponent url={url} route="clientes" name="Clientes" icon={<IoPeopleOutline />} permission={[DEV,ADM,GRN,REP]} />
                            <MenuitemComponent url={url} route="servicos" name="Serviços" icon={<IoBugOutline />} permission={[DEV,ADM,GRN]} />
                            <MenuitemComponent url={url} route="formasdepagamento" name="Formas de Pagamento" icon={<IoCardOutline />} permission={[DEV,ADM]} />
                            <MenuitemComponent url={url} route="orcamentos" name="Orçamentos" icon={<IoCalendarOutline />} permission={[DEV,ADM,GRN,CLN,REP]} />
                            <MenuitemComponent url={url} route="ordensdeservico" name="Ordens de Serviço" icon={<IoNewspaperOutline />} permission={[DEV,ADM,GRN,OPR,CLN]} />
                            <MenuitemComponent url={url} route="contasapagar" name="Contas a Pagar" icon={<IoCashOutline />} permission={[DEV,ADM]} />
                            <MenuitemComponent url={url} route="usuarios" name="Usuários" icon={<IoPersonOutline />} permission={[DEV,ADM]} />
                            <MenuitemComponent url={url} route="certificados" name="Certificados" icon={<TbFileCertificate />} permission={[DEV]} />
                            <MenuitemComponent url={url} route="unidadesdemedida" name="Unidades de Medida" icon={<TbRulerMeasure />} permission={[DEV,ADM]} />
                            <MenuitemComponent url={url} route="questionario" name="Questionário" icon={<TbQuestionMark />} permission={[DEV,ADM]} />
                            <MenuitemComponent url={url} route="pragasalvo" name="Pragas Alvo" icon={<MdOutlinePestControlRodent />} permission={[DEV,ADM]} />
                        </Col>
                        <Col flex={'auto'} className="dsh-page-content">
                            <Outlet />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Layout>
    )

}

export default Sistema;